/**
 * Clebber
 *
 * Created by Edgar Lopez on 08/30/18.
 * Copyright © 2018 intqd. All rights reserved.
 */

import {
  MYCARS_LIST,
  MYCARS_LIST_SUCCESS,
  MYCARS_CONNECTED,
  MYCARS_DELETE,
  MYCARS_UPDATE
} from './constants';

export function getCarList() {
  return { type: MYCARS_LIST };
}

export function deleteCar(cid) {
  return { type: MYCARS_DELETE, cid };
}

export function updateCar(params) {
  return { type: MYCARS_UPDATE, params };
}

export function setCarList(carList) {
  return { type: MYCARS_LIST_SUCCESS, carList };
}

export function getConnection(data) {
  return { type: MYCARS_CONNECTED, payload: data };
}
