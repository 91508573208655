import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  CHANGE_FORM,
  CURRENT_TRIP,
  FORGOT_PASSWORD,
  GET_USER,
  LOGGED_IN,
  LOGIN_REQUEST,
  LOGOUT,
  REQUEST_ERROR,
  RESET_PASSWORD,
  SET_AUTH,
  SET_CURRENT_TRIP,
  UPDATE_USER,
  SET_VALIDATE_EMAIL,
  VALIDATE_EMAIL_FAIL,
  VALIDATE_EMAIL_SUCCESS
} from '../actions/constants';
import clebber from '../api';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

export function* login(data) {
  const user = yield call(clebber.login, data.data);
  if (user) {
    yield put({ type: SET_AUTH, user }); // User is logged in (authorized) after being registered
    yield put({
      type: CHANGE_FORM,
      newFormState: { username: '', password: '' }
    }); // Clear form
    yield put(push('/dashboard')); // Go to dashboard page
  } else {
    let modal = {
      title: 'Error',
      content: 'Usuario o contraseña no valida.'
    };
    yield put({ type: REQUEST_ERROR, modal });
    yield put(push('/login')); // Go to login page
  }
}

export function* loggedIn() {
  const result = yield call(clebber.loggedIn);
  if (result) {
    yield put(push('/dashboard')); // Go to dashboard page
  }
}

export function* updateUser(params) {
  yield call(clebber.updateUser, params.data);
}

export function* onLogout() {
  yield call(clebber.logout);
  yield put(push('/login')); // Go to login page
}

export function* setCurrentTrip(data) {
  yield put({ type: CURRENT_TRIP, data }); // Go to dashboard page
}

export function* getUser() {
  const user = yield call(clebber.user);
  if (user) {
    yield put({ type: SET_AUTH, user });
  }
}

export function* forgotPassword(data) {
  const result = yield call(clebber.forgotPassword, data.params);
  if (result.status === 200) {
    yield put(
      push({
        pathname: '/forgot_success',
        state: { username: data.params.username }
      })
    );
  } else {
    let modal = {
      title: 'Oops!',
      content:
        'There was an error in the server please recheck your email or user name.'
    };
    yield put({ type: REQUEST_ERROR, modal });
  }
}

export function* resetPassword(data) {
  const result = yield call(clebber.resetPassword, data.params);
  if (result.status === 200) {
    MySwal.fire({
      type: 'success',
      title: 'Great!',
      text: 'Your password has been changed'
    });
    yield put(push('/login')); // Go to login page
  } else {
    let modal = {
      title: 'Oops!',
      content:
        'There was an error in the server please recheck your password and token.'
    };
    yield put({ type: REQUEST_ERROR, modal });
  }
}

export function* validateEmail(params) {
  const result = yield call(clebber.validateEmail, params.data);
  if (result.status === 200) {
    yield put({ type: VALIDATE_EMAIL_SUCCESS });
  } else {
    yield put({ type: VALIDATE_EMAIL_FAIL });
  }
}

export const authSaga = [
  takeLatest(LOGIN_REQUEST, login),
  takeLatest(SET_CURRENT_TRIP, setCurrentTrip),
  takeLatest(GET_USER, getUser),
  takeLatest(LOGOUT, onLogout),
  takeLatest(LOGGED_IN, loggedIn),
  takeLatest(FORGOT_PASSWORD, forgotPassword),
  takeLatest(RESET_PASSWORD, resetPassword),
  takeLatest(UPDATE_USER, updateUser),
  takeLatest(SET_VALIDATE_EMAIL, validateEmail)
];
