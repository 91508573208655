import 'assets/css/material-dashboard-react.css?v=1.4.1';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { sessionService } from 'redux-react-session';
import 'typeface-roboto';
import App from './App';
import configureStore, { history } from './configureStore';
import i18n from './variables/i18n';
//const initialState = Immutable.Map()
const store = configureStore();

sessionService.initSessionService(store);

const render = () => {
  ReactDOM.render(
    <AppContainer>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <App history={history} />
        </I18nextProvider>
      </Provider>
    </AppContainer>,
    document.getElementById('root')
  );
};
render();
if (module.hot) {
  module.hot.accept('./App', () => {
    render();
  });
}
