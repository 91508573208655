// AUTH
export const VALIDATE_EMAIL_SUCCESS = 'VALIDATE_EMAIL_SUCCESS';
export const VALIDATE_EMAIL_FAIL = 'VALIDATE_EMAIL_FAIL';
export const SET_VALIDATE_EMAIL = 'SET_VALIDATE_EMAIL';
export const LOGGED_IN = 'LOGGED_IN';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const CHANGE_FORM = 'CHANGE_FORM';
export const SET_AUTH = 'SET_AUTH';
export const RESET_PASSWORD = 'RESET_PASSWORD';

export const UPDATE_USER = 'UPDATE_USER';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const LOGOUT = 'LOGOUT';
export const REQUEST_ERROR = 'REQUEST_ERROR';
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS';

export const SET_CURRENT_CAR = 'SET_CURRENT_CAR';
export const SET_CURRENT_TRIP = 'SET_CURRENT_TRIP';
export const CURRENT_TRIP = 'CURRENT_TRIP';
export const SET_BATTERY_DATA = 'SET_BATTERY_DATA';
export const GET_USER = 'GET_USER';

// DASHBOARD
export const GET_BATTERY = 'GET_BATTERY';
export const DASHBOARD_CAR_TRIPS = 'DASHBOARD_CAR_TRIPS';
export const DASHBOARD_SET_LENGUAGE = 'DASHBOARD_SET_LENGUAGE';
export const DASHBOARD_SET_UNITS = 'DASHBOARD_SET_UNITS';
export const DASHBOARD_SET_CARLIST = 'DASHBOARD_SET_CARLIST';
export const DASHBOARD_CARLIST_SUCCESS = 'DASHBOARD_CARLIST_SUCCESS';
export const DASHBOARD_NOTIFICATIONS = 'DASHBOARD_NOTIFICATIONS';
export const DASHBOARD_CAR_NOTIFICATIONS_SUCCESS =
  'DASHBOARD_CAR_NOTIFICATIONS_SUCCESS';
export const DASHBOARD_CURRENT_CAR = 'DASHBOARD_CURRENT_CAR';
export const DASHBOARD_CAR_NOTIFICATIONS = 'DASHBOARD_CAR_NOTIFICATIONS';

// OVERVIEW
export const OVERVIEW_DATA = 'OVERVIEW_DATA';
export const OVERVIEW_CURRENT_CAR = 'OVERVIEW_CURRENT_CAR';
export const OVERVIEW_GET_TRIP_DETAIL = 'OVERVIEW_GET_TRIP_DETAIL';
export const OVERVIEW_TRIP_DETAIL_POSITION = 'OVERVIEW_TRIP_DETAIL_POSITION';
export const OVERVIEW_TRIP_HISTORY = 'OVERVIEW_TRIP_HISTORY';
export const OVERVIEW_ISSUE = 'OVERVIEW_ISSUE';
export const OVERVIEW_BATTERY = 'OVERVIEW_BATTERY';

// MYCARS
export const MYCARS_LIST = 'MYCARS_LIST';
export const MYCARS_LIST_SUCCESS = 'MYCARS_LIST_SUCCESS';
export const MYCARS_CONNECTED = 'MYCARS_CONNECTED';
export const MYCARS_DELETE = 'MYCARS_DELETE';
export const MYCARS_DELETE_SUCCESS = 'MYCARS_DELETE_SUCCESS';
export const MYCARS_DELETE_ERROR = 'MYCARS_DELETE_ERROR';
export const MYCARS_UPDATE = 'MYCARS_UPDATE';
export const MYCARS_UPDATE_SUCCESS = 'MYCARS_UPDATE_SUCCESS';
export const MYCARS_UPDATE_ERROR = 'MYCARS_UPDATE_ERROR';

//GEOFENCE
export const GEOFENCE_DATA = 'GEOFENCE_DATA';
export const GEOFENCE_SET_LIST = 'GEOFENCE_SET_LIST';
export const GEOFENCE_DELETE = 'GEOFENCE_DELETE';
export const GEOFENCE_ADD = 'GEOFENCE_ADD';
export const GEOFENCE_UPDATE = 'GEOFENCE_UPDATE';

//options
export const SETTING_OPEN = 'SETTING_OPEN';
export const DASHBOARD_CHANGE_PASSWORD = 'DASHBOARD_CHANGE_PASSWORD';
