import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router/immutable'
import createRootReducer from './reducers'
import rootSaga from "./sagas";
import createSagaMiddleware from "redux-saga";

const sagaMiddleware = createSagaMiddleware();

// Create an enhanced history that syncs navigation events with the store
export const history = createBrowserHistory();

export default function configureStore(preloadedState) {
    const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    // Creates the Redux store using our reducer and the logger and saga middlewares
    const store = createStore(
        createRootReducer(history),// new root reducer with router state
        // preloadedState,
        composeEnhancer(
            applyMiddleware(
                routerMiddleware(history), // for dispatching history actions
                // logger,
                sagaMiddleware
            )
        )
    );
    // We run the root saga automatically
    sagaMiddleware.run(rootSaga);
    // Hot reloading
    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('./reducers', () => {
            store.replaceReducer(createRootReducer(history));
        });
    }

    return store
}