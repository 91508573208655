const cardStyle = {
  card: {
    border: "0",
    marginBottom: "30px",
    marginTop: "30px",
    borderRadius: "6px",
    color: "rgba(0, 0, 0, 0.87)",
    background: "#fff",
    width: "100%",
    height: "90%",
    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    minWidth: "0",
    wordWrap: "normal",
    fontSize: ".875rem"
  },
  cardAction: {
    border: "0",
    marginBottom: "30px",
    marginTop: "30px",
    borderRadius: "6px",
    color: "rgba(0, 0, 0, 0.87)",
    background: "#fff",
    width: "100%",
    height: "70%",
    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    minWidth: "0",
    wordWrap: "normal",
    fontSize: ".875rem",
    cursor: "pointer",
    "&:hover,&:focus": {
      color: "#FFFFFF",
      boxShadow:
        "0 12px 20px -10px rgba(221, 40, 83, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(221, 40, 83, 0.2)"
    }
  },
  cardPlain: {
    background: "transparent",
    boxShadow: "none"
  },
  cardProfile: {
    marginTop: "30px",
    textAlign: "center",
    boxShadow: "0 12px 20px -10px rgba(0, 0, 0, 0.9)"
  },
  cardChart: {
    "& p": {
      marginTop: "0px",
      paddingTop: "0px"
    }
  },
  cardConnected: {
    backgroundColor: "#89CC5E"
  },
  cardDisconnected: {
    background: "linear-gradient(180deg, #2E2F2C, #4E4E4E)"
  }
};

export default cardStyle;
