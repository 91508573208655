/**
 * Clebber
 *
 * Created by Edgar Lopez on 08/30/18.
 * Copyright © 2018 intqd. All rights reserved.
 */

import {
  DASHBOARD_CAR_TRIPS,
  DASHBOARD_CARLIST_SUCCESS,
  DASHBOARD_NOTIFICATIONS,
  SETTING_OPEN,
  DASHBOARD_SET_LENGUAGE,
  DASHBOARD_SET_UNITS,
  DASHBOARD_CURRENT_CAR,
  DASHBOARD_CHANGE_PASSWORD,
  LOGGED_IN,
  LOGOUT,
  DASHBOARD_CAR_NOTIFICATIONS_SUCCESS
} from '../actions/constants';
import i18n from 'i18next';
import storage from './../utils/storage';

// The initial application state
let initialState = {
  error: '',
  status: 0,
  markers: [],
  currentCar: {
    id: 0
  },
  notifications: [],
  settingOpen: false,
  menuType: '',
  menuIndex: '',
  menu: {
    title: '',
    options: [],
    index: 0
  }
};

// Takes care of changing the application state
export default function dashboard(state = initialState, action) {
  switch (action.type) {
    case LOGOUT: {
      return initialState;
    }
    case LOGGED_IN:
      return {
        ...state,
        menuIndex: storage.getLanguage()
      };
    case DASHBOARD_CAR_NOTIFICATIONS_SUCCESS:
      return { ...state, carNotifications: action.payload.notifications };
    case DASHBOARD_CHANGE_PASSWORD:
      return { ...state, status: action.status, error: action.error };
    case DASHBOARD_CAR_TRIPS:
      return { ...state, cid: action.cid };
    case DASHBOARD_CARLIST_SUCCESS: {
      let currentCar = action.carList[0] || initialState.currentCar;
      if (state.currentCar.id !== 0) {
        currentCar = state.currentCar;
      }

      return {
        ...state,
        markers: action.carList,
        currentCar
      };
    }
    case DASHBOARD_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload
      };
    case SETTING_OPEN: {
      let index;
      switch (action.menu.type) {
        case 'Language': {
          index = storage.getLanguage();
          break;
        }
        case 'Units': {
          index = storage.getUnitSystem();
          break;
        }
        default: {
          index = 0;
          break;
        }
      }
      return {
        ...state,
        settingOpen: action.menu.open,
        menuType: action.menu.type,
        menuIndex: index
      };
    }
    case DASHBOARD_SET_LENGUAGE: {
      const lng = action.language.slice(0, 2);
      storage.setLanguage(action.language);
      i18n.changeLanguage(lng);
      return {
        ...state,
        menuIndex: action.language
      };
    }
    case DASHBOARD_SET_UNITS: {
      storage.setUnitSystem(action.units);
      return {
        ...state,
        menuIndex: action.units
      };
    }
    case DASHBOARD_CURRENT_CAR:
      return { ...state, currentCar: action.car };
    default:
      return state;
  }
}
