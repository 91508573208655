/* eslint-disable no-console */
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import logo from 'assets/img/icn_forgotpsw.png';
import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { forgotPassword } from 'actions/auth';
import * as R from 'ramda';
import Language from 'components/Language';

function Transition(props) {
  return <Slide direction='up' {...props} />;
}

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      submitted: false,
      openDialog: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate() {
    const { error } = this.props;
    if (error && this.state.submitted) {
      this.setState({ submitted: false, openDialog: true });
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { username } = this.state;
    if (username !== '') {
      this.setState({ submitted: true, username: '' });
      const params = {
        username,
        language: localStorage.getItem('language') === 'Español' ? 'es' : 'en'
      };
      this.props.forgotPassword(params);
    }
  }

  handleOk = () => {
    this.setState({ openDialog: false });
  };

  render() {
    const { classes, modal, t } = this.props;
    const { submitted, openDialog } = this.state;
    return (
      <div>
        <Language></Language>
        <Dialog
          open={openDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle id='alert-dialog-slide-title'>{modal.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description'>
              {modal.content}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleOk} color='info'>
              {t('OK')}
            </Button>
          </DialogActions>
        </Dialog>
        <GridContainer
          style={{ justifyContent: 'space-around', margin: '0 0 !important' }}
        >
          <GridItem xs={12} sm={6} md={4}>
            <Card profile>
              <CardBody>
                <h3>{t('ForgotPassword')}</h3>
                <img src={logo} alt='logo' style={{ width: '50%' }} />
                <p>{t('EnterEmail')}</p>
                <form
                  className={classes.container}
                  noValidate
                  autoComplete='off'
                  style={{ display: 'grid', marginBottom: '20px' }}
                >
                  {submitted && (
                    <CircularProgress className={classes.progress} size={50} />
                  )}
                  <TextField
                    required
                    id='name'
                    name='username'
                    label={t('UsernameEmail')}
                    className={classes.textField}
                    onChange={this.handleChange}
                    margin='normal'
                    style={{ width: '90%' }}
                  />
                </form>
                <Button
                  color='info'
                  style={{
                    width: '100%'
                  }}
                  onClick={this.handleSubmit}
                >
                  {t('Reset')}
                </Button>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    error: R.pathOr(false, ['auth', 'error'])(state),
    modal: R.pathOr({}, ['auth', 'modal'])(state)
  };
}

const mapDispatchToProps = {
  forgotPassword: forgotPassword
};

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  forgotPassword: PropTypes.func,
  error: PropTypes.bool,
  modal: PropTypes.object,
  t: PropTypes.func
};

export default compose(
  withStyles(dashboardStyle),
  withTranslation('translations'),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ForgotPassword);
