/* eslint-disable no-console */
import {
  DASHBOARD_CARLIST_SUCCESS,
  DASHBOARD_CAR_NOTIFICATIONS,
  DASHBOARD_CAR_NOTIFICATIONS_SUCCESS,
  DASHBOARD_CAR_TRIPS,
  DASHBOARD_CHANGE_PASSWORD,
  DASHBOARD_NOTIFICATIONS,
  DASHBOARD_SET_LENGUAGE,
  DASHBOARD_SET_UNITS,
  GET_BATTERY,
  SETTING_OPEN,
  SET_CURRENT_CAR
} from './constants';

export function changePassword(data) {
  return { type: DASHBOARD_CHANGE_PASSWORD, data };
}

export function carNotifications(cid) {
  return { type: DASHBOARD_CAR_NOTIFICATIONS, cid };
}

export function getTripHistory(cid) {
  return { type: DASHBOARD_CAR_TRIPS, cid };
}

export function setCarList(data) {
  return { type: DASHBOARD_CARLIST_SUCCESS, carList: data };
}

export function setNotifications(data) {
  return { type: DASHBOARD_NOTIFICATIONS, payload: data };
}

export function setCarNotifications(data) {
  return { type: DASHBOARD_CAR_NOTIFICATIONS_SUCCESS, payload: data };
}

export function onSettings(type) {
  return { type: SETTING_OPEN, menu: type };
}

export function setLenguage(language) {
  return { type: DASHBOARD_SET_LENGUAGE, language };
}

export function setUnits(units) {
  return { type: DASHBOARD_SET_UNITS, units };
}

export function setCurrentCar(car) {
  return { type: SET_CURRENT_CAR, car };
}

export function getBattery(car) {
  return { type: GET_BATTERY, car };
}
