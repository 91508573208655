import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FilledInput from '@material-ui/core/FilledInput';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Icon from '@material-ui/core/Icon';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Slide from '@material-ui/core/Slide';
import withStyles from '@material-ui/core/styles/withStyles';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Slider from '@material-ui/lab/Slider';
import image_trip_end from 'assets/img/icon_trip_end.png';
import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle.jsx';
import classNames from 'classnames';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Table from 'components/Table/TableGeofence.jsx';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import {
  Circle,
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs
} from 'react-google-maps';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose, lifecycle, withProps } from 'recompose';
import unitConverter from '../../variables/unitConverter';
import {
  addGeofence,
  deleteGeofence,
  getData,
  updateGeofence
} from './../../actions/geofence';
import { getCarList } from './../../actions/mycars';
import Geocode from './../../utils/geocode';

function Transition(props) {
  return <Slide direction='up' {...props} />;
}

const CENTER = { lat: 32.8058822, lng: -117.119443 }; // Default center

const GeofenceMap = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyD0N4e75HLlrE05-d9me1ejseeq1wOaoy4&libraries=geometry,drawing',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `300px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  lifecycle({
    UNSAFE_componentWillReceiveProps() {
      this.setState({
        zoomToPolyline: map => {
          let fitbound = false;
          if (map) {
            const bounds = new window.google.maps.LatLngBounds();
            map.props.children.forEach(child => {
              if (R.pathOr(0, ['props', 'radius'])(child).length > 1) {
                bounds.extend(
                  new window.google.maps.LatLng(
                    child.props.center.lat,
                    child.props.center.lng - child.props.radius * 0.00001
                  )
                );
                bounds.extend(
                  new window.google.maps.LatLng(
                    child.props.center.lat,
                    child.props.center.lng + child.props.radius * 0.00001
                  )
                );
                bounds.extend(
                  new window.google.maps.LatLng(
                    child.props.center.lat,
                    child.props.center.lng
                  )
                );
                fitbound = true;
              }
            });
            if (fitbound) map.fitBounds(bounds);
          }
        }
      });
    }
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    ref={props.zoomToPolyline}
    defaultZoom={12}
    center={props.center}
    defaultOptions={{
      scrollwheel: false,
      zoomControl: true,
      scaleControl: false,
      streetViewControl: false,
      mapTypeControl: false,
      styles: [
        {
          featureType: 'water',
          stylers: [{ saturation: 43 }, { lightness: -11 }, { hue: '#0088ff' }]
        },
        {
          featureType: 'road',
          elementType: 'geometry.fill',
          stylers: [{ hue: '#ff0000' }, { saturation: -100 }, { lightness: 99 }]
        },
        {
          featureType: 'road',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#808080' }, { lightness: 54 }]
        },
        {
          featureType: 'landscape.man_made',
          elementType: 'geometry.fill',
          stylers: [{ color: '#ece2d9' }]
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry.fill',
          stylers: [{ color: '#ccdca1' }]
        },
        {
          featureType: 'road',
          elementType: 'labels.text.fill',
          stylers: [{ color: '#767676' }]
        },
        {
          featureType: 'road',
          elementType: 'labels.text.stroke',
          stylers: [{ color: '#ffffff' }]
        },
        { featureType: 'poi', stylers: [{ visibility: 'off' }] },
        {
          featureType: 'landscape.natural',
          elementType: 'geometry.fill',
          stylers: [{ visibility: 'on' }, { color: '#b8cb93' }]
        },
        { featureType: 'poi.park', stylers: [{ visibility: 'on' }] },
        {
          featureType: 'poi.sports_complex',
          stylers: [{ visibility: 'on' }]
        },
        { featureType: 'poi.medical', stylers: [{ visibility: 'on' }] },
        {
          featureType: 'poi.business',
          stylers: [{ visibility: 'simplified' }]
        }
      ]
    }}
  >
    {Object.keys(props.marker).length !== 0 && (
      <Circle
        defaultOptions={{
          strokeWeight: 2,
          strokeColor: '#ff0000',
          strokeOpacity: 0.5,
          fillOpacity: 0.5,
          fillColor: '#ff0000'
        }}
        center={{ lat: props.marker.lat, lng: props.marker.lng }}
        radius={props.radius}
      />
    )}

    {Object.keys(props.marker).length !== 0 && (
      <Marker
        key={0}
        position={{
          lat: props.marker.lat,
          lng: props.marker.lng
        }}
        icon={image_trip_end}
      />
    )}
  </GoogleMap>
));

const StyledSlider = withStyles({
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid #de235b',
    '&$focused, &:hover': {
      boxShadow: `0px 0px 0px ${8}px ${fade('#de235b', 0.16)}`
    },
    '&$activated': {
      boxShadow: `0px 0px 0px ${8 * 1.5}px ${fade('#de235b', 0.16)}`
    },
    '&$jumped': {
      boxShadow: `0px 0px 0px ${8 * 1.5}px ${fade('#de235b', 0.16)}`
    }
  },
  track: {
    backgroundColor: '#de235b',
    height: 8
  },
  trackAfter: {
    backgroundColor: '#d0d7dc'
  },
  focused: {},
  activated: {},
  jumped: {}
})(Slider);

class Geofence extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    const initialState = {
      address: '',
      center: CENTER, // Default center
      cid: undefined,
      formDisable: true,
      gid: undefined,
      name: '',
      car: '',
      marker: {},
      notification: false,
      openDialog: false,
      openDialogInvalid: false,
      radius: 0,
      carName: 0
    };
    return initialState;
  };

  componentDidMount() {
    const { currentCar } = this.props;
    this.props.getData(currentCar.id);
    this.props.getCarList();
  }

  handleChangeCheck = name => event => {
    this.setState({
      [name]: event.target.checked
    });
  };

  handleChange = label => (event, value) => {
    if (label === 'radius') this.setState({ [label]: value * 1000 });
    else
      this.setState({
        [label]: event.target.value,
        formDisable: event.target.value ? false : true
      });
  };

  geofenceDetail = item => {
    if (item === undefined) return;
    const { carid, tag, id, notification, data } = item;
    Geocode.fromLatLng(data.latitude, data.longitude).then(response => {
      const address = response.results[0].formatted_address;
      this.setState({ address });
    });

    this.setState({
      cid: carid,
      gid: id,
      name: tag,
      carName: carid,
      notification: notification === 1 ? true : false,
      radius: data.radius,
      marker: { lat: data.latitude, lng: data.longitude },
      center: { lat: data.latitude, lng: data.longitude },
      formDisable: false
    });
  };

  geodecode = () => {
    const { address } = this.state;
    if (address.length !== 0) {
      Geocode.fromAddress(address).then(response => {
        const { lat, lng } = response.results[0].geometry.location;
        this.setState({ center: { lat, lng }, marker: { lat, lng } });
      });
    }
  };

  deleteGeofence = () => {
    const { cid, gid } = this.state;
    if (this.validate({ cid, gid })) {
      this.props.deleteGeofence({ cid, gid });
      this.setState(this.getInitialState());
    } else this.setState({ openDialogInvalid: true, openDialog: false });
  };

  updateAddGeofence = () => {
    const { gid } = this.state;
    if (gid) this.updateGeofence();
    else this.addGeofence();
  };

  addGeofence = () => {
    const { center, name, notification, radius, address, carName } = this.state;
    const params = {
      cid: carName,
      carid: carName,
      latitude: center.lat,
      longitude: center.lng,
      radius,
      tag: name,
      notification,
      address
    };
    if (this.validateNew(params)) {
      this.props.addGeofence(params);
      this.setState(this.getInitialState());
    } else this.setState({ openDialogInvalid: true });
  };

  updateGeofence = () => {
    const {
      carName,
      center,
      name,
      notification,
      radius,
      gid,
      address
    } = this.state;
    const params = {
      cid: carName,
      carid: carName,
      gid,
      latitude: center.lat,
      longitude: center.lng,
      radius,
      tag: name,
      notification,
      address
    };
    if (this.validate(params)) {
      this.props.updateGeofence(params);
      this.setState(this.getInitialState());
    } else this.setState({ openDialogInvalid: true });
  };

  validateNew = json => {
    let foo =
      json.tag !== '' &&
      json.carid !== 0 &&
      json.latitude &&
      json.longitude &&
      json.address !== '' &&
      json.radius !== 0;
    return foo;
  };

  validate = json => {
    let foo = json.gid !== undefined && json.cid !== undefined;
    return foo;
  };

  render() {
    const { classes, geofenceList, t, carList } = this.props;
    const {
      marker,
      center,
      formDisable,
      radius,
      address,
      name,
      notification,
      openDialog,
      openDialogInvalid,
      carName
    } = this.state;
    return (
      <div>
        <Dialog
          open={openDialogInvalid}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle id='alert-dialog-slide-title'>
            {t('Errortitle')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description'>
              {t('Errorsubtitle')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ openDialogInvalid: false })}
              color='info'
            >
              {t('OK')}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
        >
          <DialogTitle id='alert-dialog-slide-title'>
            {t('Savetitle')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description'>
              {t('Savesubtitle')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.deleteGeofence} color='info'>
              {t('OK')}
            </Button>
            <Button
              onClick={() => this.setState({ openDialog: false })}
              color='info'
            >
              {t('Cancel')}
            </Button>
          </DialogActions>
        </Dialog>
        <GridContainer>
          <GridItem xs={12} sm={5} md={5}>
            <form className={classes.container} noValidate autoComplete='off'>
              <FormControl
                variant='filled'
                className={classNames(classes.formControl, classes.textField)}
                fullWidth
              >
                <InputLabel htmlFor='filled-car-simple'>
                  {t('CarName')}
                </InputLabel>
                <Select
                  value={carName}
                  onChange={this.handleChange('carName')}
                  input={<FilledInput name='car' id='filled-car-simple' />}
                >
                  <MenuItem value={0}>
                    <em>{t('None')}</em>
                  </MenuItem>
                  {carList.map((data, key) => (
                    <MenuItem key={key} value={data.id}>
                      {data.nickname}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                required
                error={false}
                fullWidth
                id='outlined-name'
                label={t('Name')}
                className={classes.textField}
                value={name}
                onChange={this.handleChange('name')}
                margin='normal'
                variant='filled'
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={notification}
                    onChange={this.handleChangeCheck('notification')}
                    value='notification'
                  />
                }
                labelPlacement='start'
                label={t('AllowAlert')}
              />
              <div style={{ display: 'flex' }}>
                <TextField
                  required
                  error={false}
                  fullWidth
                  id='outlined-name'
                  label={t('DotAddress')}
                  className={classes.textField}
                  value={address}
                  onChange={this.handleChange('address')}
                  margin='normal'
                  variant='filled'
                />
                <Button
                  variant='fab'
                  color='info'
                  aria-label='Edit'
                  className={classes.button}
                  disabled={formDisable}
                  style={{ marginTop: 15 }}
                  onClick={this.geodecode}
                >
                  <Icon>search</Icon>
                </Button>
              </div>
              <Typography id='label'>{t('SetRadius')}</Typography>
              <div
                style={{ display: 'inline-block', padding: 20, width: '75%' }}
              >
                <StyledSlider
                  value={radius / 1000}
                  min={0}
                  max={80}
                  step={0.1}
                  aria-labelledby='label'
                  onChange={this.handleChange('radius')}
                  disabled={formDisable}
                />
              </div>

              {unitConverter.radius(radius)}
              {unitConverter.radiusUnit(radius)}
            </form>
            <Button
              size='sm'
              color='info'
              onClick={this.updateAddGeofence}
              disabled={formDisable}
            >
              {t('Save')}
            </Button>
            <Button
              size='sm'
              color='info'
              onClick={() => this.setState({ openDialog: true })}
              disabled={formDisable}
            >
              {t('Delete')}
            </Button>
          </GridItem>
          <GridItem xs={12} sm={7} md={7}>
            <GeofenceMap marker={marker} center={center} radius={radius} />
            <Button
              size='sm'
              color='info'
              onClick={() =>
                this.setState({
                  address: '',
                  center: CENTER, // Default center
                  cid: undefined,
                  formDisable: true,
                  gid: undefined,
                  name: '',
                  car: '',
                  marker: {},
                  notification: false,
                  openDialog: false,
                  openDialogInvalid: false,
                  radius: 0,
                  carName: 0
                })
              }
              disabled={formDisable}
            >
              {t('NewGeofence')}
            </Button>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <p style={{ fontWeight: 'bold' }}>{t('MyGeofence')} </p>
                <hr />
                <Table
                  tableHeaderColor='primary'
                  onClickDetail={this.geofenceDetail}
                  tableHead={[
                    t('GeofenceName'),
                    t('CarName'),
                    t('Address'),
                    t('Radius'),
                    t('Alert'),
                    t('Details')
                  ]}
                  tableData={geofenceList}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Geofence.propTypes = {
  classes: PropTypes.object.isRequired,
  geofenceList: PropTypes.arrayOf(PropTypes.object),
  tripHistory: PropTypes.arrayOf(PropTypes.object),
  currentCar: PropTypes.object,
  getData: PropTypes.func,
  deleteGeofence: PropTypes.func,
  addGeofence: PropTypes.func,
  updateGeofence: PropTypes.func,
  carList: PropTypes.arrayOf(PropTypes.object),
  getCarList: PropTypes.func,
  t: PropTypes.func
};

function mapStateToProps(state) {
  return {
    geofenceList: state.geofence.geofenceList,
    currentCar: state.dashboard.currentCar,
    carList: state.mycars.carList
  };
}

const mapDispatchToProps = {
  getCarList: getCarList,
  getData: getData,
  deleteGeofence: deleteGeofence,
  addGeofence: addGeofence,
  updateGeofence: updateGeofence
};

export default compose(
  withStyles(dashboardStyle),
  withTranslation('translations'),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Geofence);
