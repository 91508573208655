/* eslint-disable no-console */
import { call, delay, fork, put, takeLatest } from 'redux-saga/effects';
import {
  DASHBOARD_CAR_NOTIFICATIONS,
  DASHBOARD_CAR_TRIPS,
  DASHBOARD_CHANGE_PASSWORD,
  DASHBOARD_CURRENT_CAR,
  GET_BATTERY,
  SET_BATTERY_DATA,
  SET_CURRENT_CAR
} from '../actions/constants';
import {
  setCarList,
  setCarNotifications,
  setNotifications
} from '../actions/dashboard';
import { setTrips } from '../actions/overview';
import clebber from '../api';

const TIME_REFRESH = 60 * 1000; // 1min

export function* getTripHistory(params) {
  try {
    if (params.cid) {
      const trips = yield call(clebber.getTripHistory, params.cid);
      yield put(setTrips(trips));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* changePassword(params) {
  try {
    if (params.data) {
      const response = yield call(clebber.changePassword, params.data);
      yield put({
        type: 'DASHBOARD_CHANGE_PASSWORD',
        status: response.status,
        error: response.data.message
      });
    }
  } catch (error) {
    let modal = {
      title: 'Error',
      content: 'Usuario o correo electronico no valido'
    };
    yield put({ type: 'REQUEST_ERROR', modal });
  }
}

export function* carNotifications(data) {
  try {
    const notifications = yield call(clebber.getCarNotifications, data.cid);
    if (notifications) {
      yield put(setCarNotifications(notifications));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* updateDashboard() {
  while (true) {
    try {
      const isLoggedIn = yield call(clebber.loggedIn);
      if (isLoggedIn) {
        const carList = yield call(clebber.getCarList);
        if (carList) {
          yield put(setCarList(carList));
        }
        const notifications = yield call(clebber.getNotifications);
        if (notifications) {
          yield put(setNotifications(notifications));
        }
      }
    } catch (error) {
      let modal = {
        title: 'Error',
        content: 'Usuario o correo electronico no valido'
      };
      yield put({ type: 'REQUEST_ERROR', modal });
    }
    yield delay(TIME_REFRESH);
  }
}

export function* setCurrentCar(params) {
  if (params) {
    yield put({ type: DASHBOARD_CURRENT_CAR, car: params.car }); // Go to dashboard page
    yield put({ type: DASHBOARD_CAR_TRIPS, cid: params.car.id }); // Go to dashboard page
    const battery = yield call(clebber.getBattery, params.car.id);
    yield put({ type: SET_BATTERY_DATA, battery });
  }
}

export function* getBattery(params) {
  if (params) {
    const battery = yield call(clebber.getBattery, params.car);
    yield put({ type: SET_BATTERY_DATA, battery });
  }
}

export const dashboardSaga = [
  fork(updateDashboard),
  takeLatest(DASHBOARD_CAR_TRIPS, getTripHistory),
  takeLatest(SET_CURRENT_CAR, setCurrentCar),
  takeLatest(GET_BATTERY, getBattery),
  takeLatest(DASHBOARD_CHANGE_PASSWORD, changePassword),
  takeLatest(DASHBOARD_CAR_NOTIFICATIONS, carNotifications)
];
