import {
  successColor,
  boxShadow
} from 'assets/jss/material-dashboard-react.jsx';
const dashboardStyle = theme => ({
  //For tabs
  root: {
    flexGrow: 1
  },
  tabsRoot: {
    width: '10vw',
    height: '65px',
    alignSelf: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    background: 'linear-gradient(180deg,#2E2F2C,#4E4E4E)',
    color: '#F3F3F3',
    borderBottom: '1px solid #e8e8e8',
    [theme.breakpoints.down('sm')]: {
      width: '15vw'
    }
  },
  tabsIndicator: {
    width: '10vw !important',
    minWidth: '5vw !important',
    marginBottom: 1,
    alignSelf: 'center',
    justifyContent: 'center',
    backgroundColor: '#E31D37',
    [theme.breakpoints.down('sm')]: {
      width: '15vw !important'
    }
  },
  reziseWith: {
    width: '20vw',
    height: '67vh',
    [theme.breakpoints.down('sm')]: {
      width: '30vw'
    }
  },
  tabRoot: {
    width: '10vw',
    textTransform: 'initial',
    minWidth: 10,
    fontSize: 12,
    fontWidth: 'bold',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:hover': {
      color: '#E31D37',
      opacity: 1
    },
    '&$tabSelected': {
      color: '#ffff'
    },
    '&:focus': {
      color: '#ffff'
    },
    [theme.breakpoints.down('sm')]: {
      width: '15vw'
    }
  },
  resizeMap: {
    height: `88vh`,
    width: '60vw',
    [theme.breakpoints.down('sm')]: {
      height: `85vh`,
      width: '70vw'
    }
  },
  tabSelected: {},
  // tabs finish
  successText: {
    color: successColor
  },
  upArrowCardCategory: {
    width: '16px',
    height: '16px'
  },
  stats: {
    color: '#999999',
    display: 'inline-flex',
    fontSize: '12px',
    lineHeight: '22px',
    '& svg': {
      top: '4px',
      width: '16px',
      height: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px'
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      top: '4px',
      fontSize: '16px',
      position: 'relative',
      marginRight: '3px',
      marginLeft: '3px'
    }
  },
  cardCategory: {
    color: '#0D2247',
    margin: '0',
    fontWeight: '400',
    marginTop: '0',
    paddingTop: '10px',
    marginBottom: '0',
    textAlign: 'right'
  },
  cardCategoryWhite: {
    color: 'rgb(255,255,255)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardRowTextWhite: {
    color: 'rgb(255,255,255)',
    margin: '0',
    fontSize: '12px',
    marginTop: '0',
    marginBottom: '0'
  },
  cardTitle: {
    color: '#0D2247',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    textAlign: 'right',
    lineHeight: '1.5em',
    height: '4.5em',
    //overflow: 'hidden',
    '& small': {
      color: '#777',
      fontWeight: '400',
      lineHeight: '1'
    }
  },
  cardTitleCenter: {
    color: '#0D2247',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '400',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    fontSize: '12px',
    textAlign: 'center',
    '& small': {
      color: '#777',
      fontWeight: '400',
      lineHeight: '1'
    }
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontWeight: '400',
      lineHeight: '1'
    }
  },
  cardStatusWhite: {
    color: '#FFFFFF',
    marginTop: '-10px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: '#777',
      fontWeight: '400',
      lineHeight: '1'
    }
  },
  drawerPaper: {
    width: '20vw',
    height: '88vh',
    //background: "#0D2247",
    border: 'none',
    //position: "fixed",
    bottom: '0px',
    right: '0vw',
    //zIndex: "1",
    ...boxShadow,
    [theme.breakpoints.down('sm')]: {
      width: '30vw',
      height: '85vh'
    }
  },
  textAlignRight: {
    float: 'right'
  },
  progress: {
    position: 'absolute',
    left: '50%',
    right: '50%',
    bottom: '50%',
    top: '50%'
  },
  tripStats: {
    display: 'flex'
  },
  dotLine: {
    margin: '0 10px',
    border: '0',
    borderBottom: '1px dashed #DD2853',
    flexGrow: '1',
    alignSelf: 'center'
  },
  slider: {
    padding: '22px 0px'
  },
  textField: {
    marginLeft: '8px',
    marginRight: '8px'
  }
});

export default dashboardStyle;
