/**
 * Clebber
 *
 * Created by Edgar Lopez on 10/19/18.
 * Copyright © 2018 intqd. All rights reserved.
 */

import { GEOFENCE_SET_LIST, LOGOUT } from "../actions/constants";

// The initial application state
let initialState = {
  geofenceList: []
};

// Takes care of changing the application state
export default function geofence(state = initialState, action) {
  switch (action.type) {
    case LOGOUT: {
      return initialState;
    }
    case GEOFENCE_SET_LIST:
      return { ...state, geofenceList: action.geofenceList || [] };
    default:
      return state;
  }
}
