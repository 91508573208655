/**
 * Clebber
 *
 * Created by Edgar Lopez on 04/15/19.
 * Copyright © 2018 intqd. All rights reserved.
 */

import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import logo from "assets/img/logo_hsdrive.png";

const style = {
  grid: {
    padding: "0 15px !important",
    justifyContent: "space-between"
  },
  logo: {
    float: "right"
  }
};

function Terms({ ...props }) {
  return (
    <GridContainer
      style={{ justifyContent: "space-around", margin: "0 0 !important" }}
    >
      <GridItem>
        <h2><strong>T&eacute;rminos de Servicio. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</strong>
        <img className={props.classes.logo} src={logo} alt="logo" width="228" height="134"/></h2>
        <p>&nbsp;</p>
        <p><strong>Vmoviles INC (HS Drive)</strong></p>
        <p><strong>&Uacute;ltima revisi&oacute;n: 19 de marzo de 2019</strong></p>
        <p>&nbsp;</p>
        <p>Vmoviles INC ("HS", &ldquo;HS Drive&rdquo;, "nosotros", "nuestro" o la "Compa&ntilde;&iacute;a") le da la
          bienvenida a usted (el "Usuario (s)" o "usted") para usar nuestra Aplicaci&oacute;n m&oacute;vil, y nuestro
          sitio web HS Drive.app (la "Aplicaci&oacute;n", la "Aplicaci&oacute;n web", el "Sitio" y colectivamente - los
          "Servicios"). Los usuarios pueden usar el Servicio de acuerdo con los t&eacute;rminos y condiciones del
          presente.</p>
        <ol>
          <li><strong>Aceptaci&oacute;n de los T&eacute;rminos</strong></li>
        </ol>
        <p>Lea detenidamente: al ingresar y/o al utilizar el Servicio, y espec&iacute;ficamente al registrarse y/o al
          abrir una cuenta, usted reconoce que ha le&iacute;do y entendido los siguientes t&eacute;rminos de uso,
          incluidos los t&eacute;rminos de nuestra Pol&iacute;tica de privacidad disponible, en: HS
          Drive.app/pol&iacute;tica_privacidad (colectivamente, los "T&eacute;rminos") y usted acepta estar obligado por
          ellos y cumplir con todas las leyes y regulaciones aplicables con respecto a su uso de los Servicios y usted
          reconoce que estos T&eacute;rminos constituyen un enlace y contrato legal exigible entre Vmoviles INC y usted.
          SI NO EST&Aacute; DE ACUERDO CON ESTOS T&Eacute;RMINOS, NO ENV&Iacute;E, USE NI ACCEDA A LOS SERVICIOS.</p>
        <ol start="2">
          <li><strong>El Servicio</strong></li>
        </ol>
        <p>HS Drive ofrece servicios que permiten el diagn&oacute;stico del veh&iacute;culo y disposiciones de
          tasaci&oacute;n de precios de terceros proveedores de servicios en el &aacute;rea del mantenimiento y uso de
          veh&iacute;culos de acuerdo con el diagn&oacute;stico del veh&iacute;culo. El diagn&oacute;stico se realiza
          mediante un dongle OBDII ("Dongle") que est&aacute; conectado al puerto de la computadora del veh&iacute;culo
          y transmite datos a trav&eacute;s de conectividad celular, Bluetooth, Wi-Fi o mediante rutas alternativas que
          permiten conectarse a la computadora del veh&iacute;culo a trav&eacute;s de plataformas de terceros, que se
          ejecuta en un dispositivo m&oacute;vil conectado. El diagn&oacute;stico se
          iniciar&aacute; autom&aacute;ticamente una vez que el motor est&eacute; encendido y la aplicaci&oacute;n se
          ejecute (en la pantalla del tel&eacute;fono inteligente del usuario, o en el fondo).</p>
        <p>El dongle se puede comprar a trav&eacute;s del Sitio tal como se describe en los t&eacute;rminos a
          continuaci&oacute;n. En ciertos casos, puede recibir y / o puede que se le ofrezca comprar el dongle de un
          tercero que sea un socio comercial de la compa&ntilde;&iacute;a.</p>
        <p>Adem&aacute;s, en el sitio puede encontrar informaci&oacute;n completa sobre los sistemas y tecnolog&iacute;a
          de la compa&ntilde;&iacute;a y otros contenidos relacionados (incluyendo informaci&oacute;n de contacto,
          videos, texto, logotipos, iconos, im&aacute;genes, compilaciones de datos, enlaces, otros contenidos
          especializados, documentaci&oacute;n, datos, gr&aacute;ficos relacionados, propiedad intelectual y m&aacute;s
          (colectivamente, el "Contenido"). Adem&aacute;s, los usuarios pueden inscribirse a trav&eacute;s del sitio a
          nuestra lista de correo electr&oacute;nico para recibir actualizaciones sobre los desarrollos de la
          compa&ntilde;&iacute;a, etc. Tenga en cuenta que "HS Drive" es solo una plataforma para evaluaciones de
          precios, servicios de diagn&oacute;stico y disposiciones de propuestas de terceros. HS Drive no es ni
          ser&aacute; parte de ning&uacute;n trato o transacci&oacute;n entre usted y un tercero. HS Drive no es el
          fabricante del dongle, no est&aacute; siendo probado por la compa&ntilde;&iacute;a ni est&aacute; cubierto por
          la garant&iacute;a de la compa&ntilde;&iacute;a.</p>
        <ol start="3">
          <li><strong>Registro y cuenta de usuario</strong></li>
        </ol>
        <p>Algunas de las caracter&iacute;sticas de los servicios solo est&aacute;n disponibles para los usuarios
          registrados que hayan creado una cuenta personal ("Cuenta"). Para obtener m&aacute;s informaci&oacute;n sobre
          los tipos de informaci&oacute;n personal recopilados por la compa&ntilde;&iacute;a durante el proceso de
          registro, lea nuestra Pol&iacute;tica de privacidad disponible en la siguiente direcci&oacute;n: HS
          Drive.app/pol&iacute;tica_privacidad. Al abrir la cuenta, se le pedir&aacute; que brinde informaci&oacute;n
          precisa y completa para crear la cuenta, incluida una direcci&oacute;n de correo electr&oacute;nico real.
          Usted es total y exclusivamente responsable de no divulgar su contrase&ntilde;a, nombre de usuario y cuenta.
          Si desea cambiar su contrase&ntilde;a, recuperar la contrase&ntilde;a, cambiar el nombre de usuario en su
          cuenta y / o eliminar su cuenta, puede contactarnos en: contact@HSDrive.app. La modificaci&oacute;n o
          eliminaci&oacute;n de su cuenta se realizar&aacute; dentro de un tiempo razonable despu&eacute;s de su
          solicitud, y a partir de dicha eliminaci&oacute;n ya no podr&aacute; acceder a su cuenta.</p>
        <ol start="4">
          <li><strong>Adquisici&oacute;n del dongle</strong></li>
        </ol>
        <p>El sitio permite, entre otras cosas, la adquisici&oacute;n del dongle. Para realizar un pedido del dongle,
          primero deber&aacute; elegir la opci&oacute;n de compra en el sitio, que ir&aacute; seguida de una
          "P&aacute;gina del producto" que muestra informaci&oacute;n acerca del dongle, incluido, entre otros, el
          precio de venta (la " P&aacute;gina del producto "). Tenga en cuenta que HS Drive puede actualizar la
          p&aacute;gina del producto de vez en cuando, agregar o quitar informaci&oacute;n y actualizar o cambiar el
          precio del dongle. Se aclara que las im&aacute;genes en el sitio son solo ilustrativas. Los precios que se
          muestran en el sitio no incluyen el IVA de acuerdo con la ley aplicable, a menos que se indique lo contrario.
          Para comprar el dongle en el sitio, se le pedir&aacute; al usuario que solicite la compra del dongle (el
          "Comprador") que ingrese cierta informaci&oacute;n como su nombre, direcci&oacute;n, direcci&oacute;n de
          correo electr&oacute;nico y m&eacute;todo de pago (tarjeta de cr&eacute;dito).</p>
        <p>Aseg&uacute;rese de proporcionar informaci&oacute;n precisa y correcta, ya que de lo contrario no podemos
          garantizar la finalizaci&oacute;n de la compra. Proporcionar informaci&oacute;n falsa es un delito y quien lo
          haga puede estar sujeto a procedimientos legales penales y / o civiles. "HS Drive" tiene derecho a recibir una
          indemnizaci&oacute;n completa de los compradores que brinden informaci&oacute;n falsa y / o que puedan
          emprender acciones legales en consecuencia, incluidas las reclamaciones por da&ntilde;os y perjuicios.</p>
        <p>En el momento de la compra, se realizar&aacute; una verificaci&oacute;n de los m&eacute;todos de pago y con
          el recibo de confirmaci&oacute;n de la compa&ntilde;&iacute;a de la tarjeta de cr&eacute;dito, el comprador
          recibir&aacute; un aviso de que la compra ha sido confirmada. Por la presente se aclara que el cargo se
          implementar&aacute; inmediatamente despu&eacute;s de la compra. Tenga en cuenta que la empresa verificadora,
          buro de cr&eacute;dito no son responsables de la transacci&oacute;n entre Vmoviles INC y el Comprador, y no
          ofrecen ninguna garant&iacute;a para el dongle, incluyendo, sin limitaci&oacute;n, su calidad y / o
          entrega.</p>
        <p>La finalizaci&oacute;n de la transacci&oacute;n depende de la existencia disponible del dongle en el momento
          de la finalizaci&oacute;n de la compra. En caso de que el dongle est&eacute; agotado y / o haya un problema
          con su suministro, por cualquier motivo, Vmoviles INC puede informar al Comprador de la cancelaci&oacute;n del
          pedido. Se debe notificar al Comprador por correo electr&oacute;nico. En tal caso, el Comprador no
          tendr&aacute; ning&uacute;n reclamo contra Vmoviles INC y al comprar el dongle, el Comprador renuncia a dicho
          reclamo. Vmoviles INC se reserva el derecho de limitar la cantidad de dongles por pedido.</p>
        <p>El Comprador puede optar por recibir el dongle a trav&eacute;s de un env&iacute;o directo, lo que implica
          cargos adicionales (el "Cargo por env&iacute;o") entregados directamente al hogar del Comprador y / o a
          cualquier otro destino de su elecci&oacute;n. En tal caso, Vmoviles INC proporcionar&aacute; el dongle al
          Comprador para ser entregado con una empresa de paqueter&iacute;a, seg&uacute;n lo elija Vmoviles INC a su
          exclusivo criterio dentro de aproximadamente 60 d&iacute;as a partir de la fecha de pedido del Dongle.
          Vmoviles INC no es responsable de ning&uacute;n acto u omisi&oacute;n de la empresa de env&iacute;o, incluida
          la falta de entrega a tiempo, y en ese caso Vmoviles INC har&aacute; todos los esfuerzos posibles para
          proporcionar un producto alternativo lo antes posible.</p>
        <ol start="5">
          <li><strong>Cancelaci&oacute;n<span className="Apple-converted-space">&nbsp;</span></strong></li>
        </ol>
        <p>La cancelaci&oacute;n se realizar&aacute; de conformidad con las disposiciones de la legislaci&oacute;n y
          regulaci&oacute;n de protecci&oacute;n del consumidor aplicable. La devoluci&oacute;n del precio de la
          transacci&oacute;n en caso de cancelaci&oacute;n se efectuar&aacute; de la misma manera y de acuerdo con la
          ley.</p>
        <p>La cancelaci&oacute;n de dicha orden deber&aacute; realizarse por escrito mediante un mensaje de correo
          electr&oacute;nico a la direcci&oacute;n de correo electr&oacute;nico de HS Drive: contact@HS Drive.app.</p>
        <p>Vmoviles INC puede cancelar una transacci&oacute;n y / o una adquisici&oacute;n en todo o en parte: en caso
          de que ocurra un error en la propuesta, ya sea en el precio del Dongle o en la descripci&oacute;n del Dongle;
          en caso de fuerza mayor, actos de guerra, terrorismo u hostilidades que impidan la continuaci&oacute;n de la
          venta, implementaci&oacute;n o participaci&oacute;n en la venta de manera adecuada; si descubre que la
          transacci&oacute;n fue parte de una actividad ilegal de los Compradores y / o cualquiera de ellos y / o un
          tercero; si descubre que los Compradores pueden devolver y vender su Dongle a un tercero y / o comerciar
          con &eacute;l. El aviso de cancelaci&oacute;n de la venta se entregar&aacute; al Comprador por correo
          electr&oacute;nico y / o por escrito a la direcci&oacute;n especificada por el Comprador en el momento de la
          compra.</p>
        <ol start="6">
          <li><strong>Restricciones de uso</strong></li>
        </ol>
        <p>Existen ciertas conductas que est&aacute;n estrictamente prohibidas en nuestro Servicio. Por favor, lea
          cuidadosamente las siguientes restricciones. Su incumplimiento de las disposiciones establecidas puede
          resultar en el presente (a discreci&oacute;n exclusiva de Vmoviles INC) al finalizar su acceso al sitio y
          tambi&eacute;n puede exponerlo a responsabilidad civil y / o criminal.</p>
        <p>Usted no puede hacerlo, ya sea usted o cualquier persona en su nombre: (a) copie, modifique, adapte,
          traduzca, realice ingenier&iacute;a inversa, descompile o desensamble cualquier parte del Contenido de
          nuestros Servicios, de ninguna manera, exhibici&oacute;n p&uacute;blica, representaci&oacute;n o
          distribuirlos; (b) hacer uso del Contenido en el Servicio para cualquier prop&oacute;sito, o replicar y / o
          copiar el Contenido sin el previo consentimiento por escrito de Vmoviles INC; (c) crear un entorno de
          navegador o borde alrededor del contenido de Vmoviles INC (sin marcos ni enlaces en l&iacute;nea); (d)
          interferir o violar los derechos de privacidad o de otro visitante de otro Sitio o del usuario, o recopilar o
          recopilar informaci&oacute;n personal identificable sobre visitantes o usuarios de nuestros servicios sin su
          consentimiento expreso, incluido el uso de cualquier "robot", "ara&ntilde;a", aplicaci&oacute;n de
          b&uacute;squeda del sitio o de cualquier parte del mismo, incluidas las bases de datos de HS Drive, o la
          aplicaci&oacute;n de recuperaci&oacute;n o partes de la misma, incluido el &iacute;ndice de datos u otro
          dispositivo o proceso manual o autom&aacute;tico para recuperar, indexar o extraer datos; (e) difamar, abusar,
          acosar, acechar, amenazar o violar los derechos legales de otros; (f) transmitir o poner a disposici&oacute;n,
          en conexi&oacute;n con los servicios, virus, gusanos, caballos de Troya, bombas de tiempo, virus web, spyware
          o cualquier otro c&oacute;digo, archivo o programa inform&aacute;tico que pueda o est&eacute; destinado a
          da&ntilde;ar o secuestrar la operaci&oacute;n de cualquier hardware, software o equipo de telecomunicaciones,
          o cualquier otro c&oacute;digo o componente potencialmente da&ntilde;ino, disruptivo o invasivo; (g)
          interferir o interrumpir el funcionamiento de los servicios, o los servidores o redes que alojan los
          servicios, o desobedecer cualquier requisito, procedimiento, pol&iacute;tica o regulaci&oacute;n de dichos
          servidores o redes; (h) vender, licenciar, hacer un uso no personal o explotar para cualquier fin comercial
          cualquier uso o acceso al Contenido de los Servicios sin el consentimiento expl&iacute;cito de la
          Compa&ntilde;&iacute;a, incluido el uso de cualquier Contenido en el mismo; (i) enmarcar o reflejar cualquier
          parte de los Servicios sin la previa autorizaci&oacute;n expresa por parte de Vmoviles INC; (j) crear una base
          de datos mediante la descarga y el almacenamiento sistem&aacute;tico de todo o parte del Contenido del
          Servicio; (k) reenviar los datos generados de los Servicios sin el consentimiento previo por escrito de
          Vmoviles INC; (l) transferir y / o asignar la contrase&ntilde;a de la cuenta del usuario, incluso temporal, a
          un tercero; o (m) usar los Servicios para cualquier prop&oacute;sito ilegal, inmoral o no autorizado.</p>
        <ol start="7">
          <li><strong>Pol&iacute;tica de privacida</strong>d</li>
        </ol>
        <p>Vmoviles INC respeta su privacidad y se compromete a proteger la informaci&oacute;n que comparte con ella.
          Creemos que tiene derecho a conocer nuestras pr&aacute;cticas y nuestros procedimientos con respecto a la
          informaci&oacute;n que HS Drive recopila y utiliza cuando utiliza los Servicios. La pol&iacute;tica de
          privacidad, las pr&aacute;cticas y el tipo de informaci&oacute;n recopilada de HS Drive se describen en
          nuestra Pol&iacute;tica de privacidad, disponible aqu&iacute;: HS Drive.app/pol&iacute;tica_privacidad.</p>
        <ol start="8">
          <li><strong>Derechos de propiedad intelectual</strong></li>
        </ol>
        <p>La Compa&ntilde;&iacute;a le otorga una licencia limitada, personal, no exclusiva, no asignable, no
          negociable, que no se puede usar para emitir sublicencias y que puede cancelarse por completo, para usar: (a)
          la Aplicaci&oacute;n en su tel&eacute;fono inteligente o en un tel&eacute;fono inteligente que
          est&aacute; bajo su control; (b) la aplicaci&oacute;n web; (c) el Sitio, sujeto a los t&eacute;rminos y
          condiciones de este acuerdo. Estos t&eacute;rminos y condiciones no le otorgan ning&uacute;n derecho en la
          Aplicaci&oacute;n y / o en la Aplicaci&oacute;n Web y / o el Sitio o en relaci&oacute;n con esto, m&aacute;s
          bien de un derecho limitado a usarlo de acuerdo con los T&eacute;rminos; y (d) el Dongle OBD y la propiedad
          intelectual provista se encuentran de acuerdo con los t&eacute;rminos y condiciones establecidos en este
          acuerdo. Las marcas y el logotipo de HS Drive y todos los dem&aacute;s identificadores de propiedad utilizados
          por HS Drive en relaci&oacute;n con los Servicios (las "Marcas Comerciales HS Drive") son todas marcas
          comerciales y / o nombres comerciales de la Compa&ntilde;&iacute;a, est&eacute;n o no registrados. Todas las
          dem&aacute;s marcas comerciales, nombres comerciales, marcas de identificaci&oacute;n y logotipos que puedan
          aparecer en los Servicios pertenecen a los propietarios de sus derechos ("Marcas comerciales de terceros"). No
          se otorga ning&uacute;n derecho, licencia o inter&eacute;s para las Marcas comerciales HS Drive o marcas
          comerciales de terceros, y por lo tanto evitar&aacute; utilizar cualquiera de esas marcas a menos que tal uso
          haya sido espec&iacute;ficamente autorizado en los T&eacute;rminos. Los servicios, y todos y cada uno de los
          derechos de propiedad intelectual correspondientes, incluidos, entre otros, inventos, patentes y solicitudes
          de patente, marcas comerciales, nombres comerciales, logotipos, materiales protegidos por derechos de autor,
          gr&aacute;ficos, texto, im&aacute;genes, dise&ntilde;os (incluido el "aspecto y sentir "del Servicio",
          especificaciones, m&eacute;todos, procedimientos, informaci&oacute;n, know-how, algoritmos, datos, datos
          t&eacute;cnicos, caracter&iacute;sticas interactivas, c&oacute;digo fuente y objeto, archivos, interfaz y
          secretos comerciales, est&eacute;n o no registrados o puedan registrarse o no (colectivamente, "Propiedad
          Intelectual"), son propiedad y / o est&aacute;n bajo licencia de HS Drive y est&aacute;n sujetos a derechos de
          autor y otros derechos de propiedad intelectual aplicables bajo las leyes Mexicanas, leyes extranjeras y
          convenciones internacionales. No puede copiar, distribuir, exhibir, ejecutar p&uacute;blicamente, poner a
          disposici&oacute;n del p&uacute;blico, reducir a la forma legible, descompilar, desensamblar, adaptar,
          sublicenciar, hacer cualquier uso comercial, vender, alquilar, prestar, procesar, compilar, realizar
          ingenier&iacute;a inversa, combinar con otro software, traducir, modificar o crear trabajos derivados de
          cualquier material sujeto a los derechos de propiedad de HS Drive, incluida la propiedad intelectual de HS
          Drive, por usted o por cualquier persona en su nombre, de cualquier manera o por cualquier medio, a menos que
          est&eacute; expresamente permitido en los t&eacute;rminos.</p>
        <p>Por la presente, se le proh&iacute;be eliminar, separar o eliminar la desconexi&oacute;n del contenido de
          todos los avisos de derechos de autor, restricciones y signos que indiquen derechos de propiedad de HS Drive o
          sus licenciantes, incluidos [&reg;], marca de derechos de autor [&copy;] o marca registrada [&trade;]
          contenidos en acompa&ntilde;ando el Contenido incluido en los Servicios, y usted declara y garantiza que
          cumplir&aacute; con todas las leyes aplicables a este respecto.</p>
        <ol start="9">
          <li><strong>Disponibilidad</strong></li>
        </ol>
        <p>La disponibilidad y funcionalidad de nuestros Servicios depende de varios factores, incluidos los servicios
          de terceros, como las redes de comunicaci&oacute;n. Vmoviles INC no garantiza que los Servicios
          funcionar&aacute;n y / o estar&aacute;n disponibles en todo momento sin interrupci&oacute;n o
          interrupci&oacute;n, o que estar&aacute;n libres de errores.</p>
        <ol start="10">
          <li><strong>Cambios a nuestros servicios</strong></li>
        </ol>
        <p>Vmoviles INC se reserva el derecho de modificar, corregir, enmendar, mejorar, mejorar, hacer cualquier otro
          cambio o interrumpir, temporal o permanentemente, los servicios (o cualquier parte del mismo, incluido, entre
          otros, el contenido) sin previo aviso, en cualquier momento y a su entera discreci&oacute;n. Usted acepta que
          HS Drive no ser&aacute; responsable ante usted ni ante ning&uacute;n tercero por ninguna modificaci&oacute;n,
          suspensi&oacute;n o interrupci&oacute;n de los servicios ni del contenido incluido en nuestros servicios.</p>
        <ol start="11">
          <li><strong>Descargo de responsabilidad y garant&iacute;as</strong></li>
        </ol>
        <p>Vmoviles INC NO GARANTIZA NI HACE NINGUNA REPRESENTACI&Oacute;N CON RESPECTO AL USO, EXPL&Iacute;CITAMENTE NI
          IMPL&Iacute;CITAMENTE, INCLUYENDO LOS SERVICIOS PROPORCIONADOS POR TERCEROS, AS&Iacute; COMO EL CONTENIDO
          GENERADO POR EL USUARIO (SEG&Uacute;N SE DEFINE A CONTINUACI&Oacute;N) O LOS RESULTADOS DEL USO DE LOS
          SERVICIOS Y / O EL DONGLE. Vmoviles INC NO SER&Aacute; RESPONSABLE POR NING&Uacute;N DA&Ntilde;O, INCLUIDOS,
          ENTRE OTROS, DA&Ntilde;OS DIRECTOS, INDIRECTOS, ESPECIALES, INCIDENTALES O CONSECUENCIALES DE CUALQUIER TIPO,
          YA SE HAYA CAUSADO EN CONSECUENCIA O EN RELACI&Oacute;N CON EL USO DE LOS SERVICIOS, INCLUIDOS LOS SERVICIOS Y
          LA AYUDA QUE SON PROPORCIONADOS O VENDIDOS POR TERCEROS, INCLUYENDO A TRAV&Eacute;S DE LOS SERVICIOS O EL
          CONTENIDO GENERADO POR EL USUARIO, O CUALQUIER OTRA INFORMACI&Oacute;N QUE EMPIEZA DEL USO DE LOS SERVICIOS,
          YA SEA QUE LA COMPA&Ntilde;&Iacute;A HUBIESE INFORMADO AL USUARIO DE DICHO DA&Ntilde;O POSIBLE.</p>
        <p>LOS SERVICIOS, INCLUYENDO SIN LIMITACI&Oacute;N, CUALQUIER CONTENIDO DISPONIBLE EN LOS SERVICIOS SE
          PROPORCIONA "TAL CUAL", SIN NINGUNA GARANT&Iacute;A DE NING&Uacute;N TIPO, EXPRESA O IMPL&Iacute;CITA,
          INCLUIDAS, ENTRE OTRAS, GARANT&Iacute;AS DE T&Iacute;TULO O DE NO INFRACCI&Oacute;N O GARANT&Iacute;AS
          IMPL&Iacute;CITAS DE USO, COMERCIABILIDAD O IDONEIDAD PARA UN PROP&Oacute;SITO O USO PARTICULAR. HS DRIVE
          NIEGA Y NO OFRECE DECLARACIONES O GARANT&Iacute;AS EN CUANTO A LA EXACTITUD, CALIDAD, DISPONIBILIDAD,
          FIABILIDAD, IDONEIDAD, INTEGRIDAD, VERACIDAD, UTILIDAD O EFECTIVIDAD DE CUALQUIER CONTENIDO DISPONIBLE EN LOS
          SERVICIOS. EL USO DE LOS SERVICIOS, EL CONTENIDO ES BAJO SU PROPIO RIESGO Y HS DRIVE NO TENDR&Aacute; NINGUNA
          RESPONSABILIDAD RELACIONADA CON DICHO USO.</p>
        <p>Vmoviles INC NO GARANTIZA QUE EL FUNCIONAMIENTO DE LOS SERVICIOS ES O SER&Aacute; SEGURO, PRECISO, COMPLETO,
          ININTERRUMPIDO, SIN ERROR, O LIBRE DE VIRUS, GUSANOS, OTROS COMPONENTES DA&Ntilde;INOS U OTRAS LIMITACIONES
          DEL PROGRAMA.</p>
        <p>POR ESTE MEDIO SE ACLARA QUE HS DRIVE NO ES RESPONSABLE DE LA NATURALEZA, CALIDAD O RENDIMIENTO DEL DONGLE.
          SIN EMBARGO, EN EL CASO DE QUE EL DONGLE RECIBIDO SE ENCUENTRE DA&Ntilde;ADO, Vmoviles INC
          USAR&Aacute; ESFUERZOS COMERCIALMENTE RAZONABLES PARA PROPORCIONAR UN PRODUCTO DE REEMPLAZO LO ANTES
          POSIBLE.</p>
        <ol start="12">
          <li><strong>Limitaci&oacute;n de responsabilidad</strong></li>
        </ol>
        <p>EN NING&Uacute;N CASO Vmoviles INC, INCLUYENDO SUS OFICIALES, DIRECTORES, ACCIONISTAS, EMPLEADOS,
          SUBCONTRATISTAS Y SUS AGENTES, SER&Aacute;N RESPONSABLES POR DA&Ntilde;OS Y PERJUICIOS, INCLUIDOS, ENTRE
          OTROS, DA&Ntilde;OS DIRECTOS, INDIRECTOS, ESPECIALES, INCIDENTALES O CONSECUENCIALES DE CUALQUIER TIPO, YA SEA
          EN UNA ACCI&Oacute;N DE CONTRATO, NEGLIGENCIA U OTRAS ACCIONES TARD&Iacute;AS RESULTANTES DE O RESULTANTES DE
          LOS SERVICIOS, LA APLICACI&Oacute;N, EL CONTENIDO Y LA AYUDA PROPORCIONADOS O VENDIDOS POR OTROS USUARIOS O EL
          USO O INCAPACIDAD DE USAR LOS SERVICIOS, EL CONTENIDO, LA P&Eacute;RDIDA DE FONDO DE COMERCIO O GANANCIAS, EL
          DESEMPE&Ntilde;O O INCUMPLIMIENTO DE HS DRIVE PARA REALIZAR BAJO ESTOS T&Eacute;RMINOS, CUALQUIER OTRO ACTO U
          OMISI&Oacute;N DE HS DRIVE POR CUALQUIER OTRA CAUSA; O BASADO EN EL INCUMPLIMIENTO DE LA GARANT&Iacute;A, EL
          INCUMPLIMIENTO DEL CONTRATO, LA NEGLIGENCIA, LA RESPONSABILIDAD ESTRICTA O CUALQUIER OTRA TEOR&Iacute;A LEGAL,
          INDEPENDIENTEMENTE DE SI HS DRIVE HA SIDO ADVERTIDA DE LA POSIBILIDAD DE DICHOS DA&Ntilde;OS.</p>
        <ol start="13">
          <li><strong>Indemnizaci&oacute;n</strong></li>
        </ol>
        <p>Usted acepta defender, indemnizar y mantener exento de responsabilidad a Vmoviles INC de y contra todos y
          cada uno de los reclamos, da&ntilde;os, obligaciones, p&eacute;rdidas, responsabilidades, costos, deudas y
          gastos (incluidos, entre otros, honorarios de abogados) que surjan de: (a) su uso de los servicios; (b) su
          violaci&oacute;n de cualquier t&eacute;rmino de estos T&eacute;rminos; (c) cualquier da&ntilde;o de cualquier
          tipo, ya sea directo, indirecto, especial o consecuente, que pueda causar a un tercero que se relacione con su
          uso de los Servicios (incluida su violaci&oacute;n de los derechos de un tercero); y (d) cualquier reclamo de
          recursos financieros u otras compensaciones debido a una decisi&oacute;n tomada o no tomada o cualquier
          acci&oacute;n que se haya realizado o no se haya realizado en base al Contenido, resultado o cualquier otro
          dato resultante del uso de los Servicios.</p>
        <ol start="14">
          <li><strong>Enmiendas a los T&eacute;rminos</strong></li>
        </ol>
        <p>Vmoviles INC puede cambiar los T&eacute;rminos de vez en cuando, a su entera discreci&oacute;n y sin previo
          aviso, y por lo tanto solicita a todos sus Usuarios que vuelvan a visitar esta p&aacute;gina a menudo. Le
          notificaremos sobre cambios sustanciales de estos T&eacute;rminos al cambiar el enlace a los T&eacute;rminos
          en el Servicio y / o al enviarle un correo electr&oacute;nico con respecto a dichos cambios a la
          direcci&oacute;n de correo electr&oacute;nico que proporcion&oacute; durante el registro. Dichos cambios
          sustanciales entrar&aacute;n en vigor siete (7) d&iacute;as despu&eacute;s de que se haya proporcionado dicha
          notificaci&oacute;n en los Servicios o se haya enviado por correo electr&oacute;nico. De lo contrario, todos
          los dem&aacute;s cambios a estos T&eacute;rminos entrar&aacute;n en vigor a partir de la fecha indicada en
          "&Uacute;ltima revisi&oacute;n" y su uso continuado de los Servicios a partir de la &uacute;ltima fecha
          revisada constituir&aacute; la aceptaci&oacute;n de estos cambios, y acepta estar sujeto a dichos cambios.</p>
        <ol start="15">
          <li><strong>Terminaci&oacute;n de nuestra operaci&oacute;n de Servicios</strong></li>
        </ol>
        <p>En cualquier momento, Vmoviles INC puede bloquear su acceso a nuestros Servicios y / o limitar temporal o
          permanentemente su acceso a nuestros Servicios, a su entera discreci&oacute;n, adem&aacute;s de cualquier otro
          recurso que pueda estar disponible para Vmoviles INC bajo cualquier ley aplicable. Tales acciones de Vmoviles
          INC pueden tomarse si Vmoviles INC considera que ha incumplido alguno de estos T&eacute;rminos de alguna
          manera.</p>
        <p>Adem&aacute;s, Vmoviles INC puede, en cualquier momento, a su exclusivo criterio, suspender el funcionamiento
          de nuestros Servicios o parte de ellos, de forma temporal o permanente, sin previo aviso. Usted acepta y
          reconoce que Vmoviles INC no asume ninguna responsabilidad con respecto a, o en relaci&oacute;n con la
          terminaci&oacute;n de nuestra operaci&oacute;n de Servicios y la p&eacute;rdida de cualquier
          informaci&oacute;n. Las disposiciones de las secciones Propiedad intelectual, Exenci&oacute;n de
          responsabilidad y Garant&iacute;as, Limitaci&oacute;n de responsabilidad, Indemnizaci&oacute;n y Generalidades
          seguir&aacute;n vigentes tras la terminaci&oacute;n o el vencimiento de los T&eacute;rminos.</p>
        <ol start="16">
          <li><strong>Menores</strong></li>
        </ol>
        <p>Para disfrutar de nuestros Servicios, debe ser mayor de diecis&eacute;is (16) a&ntilde;os. Nos reservamos el
          derecho de solicitar prueba de edad en cualquier etapa para que podamos verificar que los menores de
          diecis&eacute;is (16) a&ntilde;os no utilicen nuestros Servicios. En caso de que sepamos que una persona menor
          de diecis&eacute;is (16) a&ntilde;os est&aacute; utilizando nuestros Servicios, prohibiremos e impediremos que
          dicho Usuario acceda a nuestros Servicios y haremos todos los esfuerzos posibles para eliminar cualquier
          Informaci&oacute;n Personal (como tal t&eacute;rmino se define en nuestra Pol&iacute;tica de Privacidad con
          respecto a dicho Usuario).</p>
        <ol start="17">
          <li><strong>Enlaces a sitios de terceros</strong></li>
        </ol>
        <p>Nuestros Servicios pueden contener enlaces a sitios web o recursos no pertenecientes a Vmoviles INC ("Sitios
          de terceros"), ya sean o no proporcionados por Vmoviles INC. Esos sitios vinculados se
          proporcionan &uacute;nicamente para su conveniencia. Por la presente, reconoce que estos sitios vinculados no
          est&aacute;n bajo el control de Vmoviles INC y no es responsable de la disponibilidad de tales sitios o
          recursos externos, y no respalda y no es responsable de cualquier contenido publicitario, productos u otra
          informaci&oacute;n contenida ah&iacute; o disponible de tales sitios vinculados o cualquier enlace contenido
          en un sitio vinculado. Adem&aacute;s, reconoce y acepta que Vmoviles INC no ser&aacute; responsable ni
          ser&aacute; responsable, directa o indirectamente, de ning&uacute;n da&ntilde;o o p&eacute;rdida causados ​​o
          supuestamente causados ​​por o en conexi&oacute;n con el uso o la dependencia de dicho contenido, bienes o
          servicios disponibles en o a trav&eacute;s de dichos sitios o recursos vinculados. La mayor&iacute;a de los
          sitios de terceros contienen documentos legales sobre la pol&iacute;tica de privacidad de los t&eacute;rminos
          de uso, y le recomendamos que lea cuidadosamente dichos documentos antes de usar esos sitios.</p>
        <ol start="18">
          <li><strong>Proveedores de plataformas de uso general</strong></li>
        </ol>
        <p>Como puede descargar la Aplicaci&oacute;n desde una plataforma de terceros (por ejemplo, App Store), un
          proveedor de servicios o un distribuidor (los "Proveedores de la Plataforma"), su uso de la Aplicaci&oacute;n
          tambi&eacute;n puede estar sujeto a las reglas de uso de los Proveedores de la Plataforma en conexi&oacute;n
          con su uso de la Aplicaci&oacute;n ("Reglas de uso"). Tenga en cuenta que las reglas de uso pueden aplicarse a
          usted y es su responsabilidad determinar, mientras que las reglas de uso rigen su uso de la Aplicaci&oacute;n.
          Usted acepta cumplir con todas las reglas de uso de la plataforma que los proveedores le aplican. Las Reglas
          de uso pueden, en algunos casos, prevalecer sobre otras reglas, incluidos los t&eacute;rminos de este acuerdo.
          Por el presente, declara y garantiza que no est&aacute; impedido de descargar y / o utilizar la
          Aplicaci&oacute;n de acuerdo con cualquier ley o de conformidad con los T&eacute;rminos. Cualquier descarga y
          / o uso de la Aplicaci&oacute;n por parte de cualquier persona que est&eacute; prohibida por la ley o por los
          T&eacute;rminos est&aacute; estrictamente prohibida. Usted acepta y acepta que los Proveedores de la
          Plataforma no tienen responsabilidad alguna por la Aplicaci&oacute;n o el Contenido de esta. Usted reconoce
          que los Proveedores de la Plataforma no tienen el compromiso de proporcionar servicios de mantenimiento ni
          asistencia de acuerdo con la Aplicaci&oacute;n.</p>
        <ol start="19">
          <li><strong>Condiciones especiales relacionadas con los activos de terceros</strong></li>
        </ol>
        <p>Los Servicios pueden usar o incluir cualquier software desarrollado por terceros, archivos y componentes
          sujetos a los t&eacute;rminos y condiciones de licencia de c&oacute;digo abierto de la licencia o de un
          tercero ("Componentes de terceros"). Estas condiciones no se aplican a los componentes de terceros que
          acompa&ntilde;an o est&aacute;n incluidos en la aplicaci&oacute;n y la empresa no se hace responsable de
          ellos. Usted reconoce que la Compa&ntilde;&iacute;a no es el autor, propietario o licenciante de componentes
          de terceros. La empresa elimina la responsabilidad y responsabilidad de cualquier tipo, expresa o
          impl&iacute;cita, por la calidad, capacidad, funcionamiento, rendimiento o idoneidad de cualquier componente
          de terceros. Absolutamente la aplicaci&oacute;n o cualquier parte de esta (excepto los elementos de terceros
          contenidos en ella) se considerar&aacute; de c&oacute;digo abierto, dominio p&uacute;blico o disponible
          p&uacute;blicamente.</p>
        <ol start="20">
          <li><strong>Contenido generado por el usuario</strong></li>
        </ol>
        <p>El Servicio le permite subir, publicar, publicar y poner a disposici&oacute;n sus propios materiales con
          derecho de autor tales como obras literarias, textos, im&aacute;genes, fotos, videos, tiras y cualquier otro
          material patentado, incluidos, entre otros, todos los materiales del Tercero. Proveedores (el "Contenido
          generado por el usuario"). Al utilizar los Servicios, aseg&uacute;rese de mantener y respetar la propiedad
          intelectual, la propiedad personal y la pol&iacute;tica de privacidad de terceros, para terceros que sean
          titulares del derecho del Contenido generado por el usuario que el Usuario haya cargado en los Servicios.
          Vmoviles INC no ser&aacute; responsable ni ser&aacute; responsable, directa o indirectamente, de ning&uacute;n
          da&ntilde;o, p&eacute;rdida, costo o gasto que pueda sufrir el Usuario debido al Contenido generado por el
          usuario.</p>
        <p>Siempre que su Contenido generado por el usuario est&eacute; sujeto a la ley de derechos de autor aplicable,
          dicho Contenido generado por el usuario permanecer&aacute; en todo momento, y en la medida permitida por la
          ley, como su &uacute;nica y exclusiva propiedad. Usted acepta y declara que el Contenido generado por el
          usuario no es confidencial. El Usuario entiende que es el &uacute;nico responsable del Contenido generado por
          el usuario y de los resultados o consecuencias, ya sean expresos o impl&iacute;citos, de la carga del
          Contenido generado por el usuario a los Servicios. El Usuario declara y garantiza que el Contenido generado
          por el usuario es verdadero, exacto y completo. Usted declara y garantiza que es el propietario
          leg&iacute;timo del Contenido generado por el usuario que carga, o que tiene (y seguir&aacute; teniendo) todas
          las licencias, derechos, consentimientos y permisos necesarios de los leg&iacute;timos propietarios de dicho
          Contenido generado por el usuario. , que son necesarios para usar y permitir que la Compa&ntilde;&iacute;a use
          el Contenido generado por el usuario que usted carga y que dicho Contenido generado por el usuario no infringe
          los derechos de autor u otros derechos de terceros (incluidos, entre otros, la pol&iacute;tica de privacidad,
          los derechos de publicaci&oacute;n, los derechos de autor o cualquier otra propiedad intelectual).</p>
        <p>Usted ser&aacute; responsable de contar con el consentimiento requerido bajo cualquier ley relacionada con la
          publicaci&oacute;n de informaci&oacute;n personal de otros usuarios, y deber&aacute; cumplir con cualquier ley
          estatal o federal en relaci&oacute;n con el Contenido generado por el usuario. Usted acepta expresamente que
          el Contenido generado por el usuario no contendr&aacute; promociones, anuncios, concursos o loter&iacute;as no
          autorizados.</p>
        <p>Usted acepta que no publicar&aacute; ni cargar&aacute; ning&uacute;n Contenido generado por el usuario que
          contenga contenido que sea ilegal que posea, publique o cargue en el pa&iacute;s en el que reside o que
          ser&iacute;a ilegal para la Compa&ntilde;&iacute;a usar o poseer en relaci&oacute;n con el Servicio (incluido,
          entre otros, contenido que sea: difamatorio, pornogr&aacute;fico, obsceno, acosador, amenazante, insultante o
          fraudulento).</p>
        <p>Se aclara que, aunque Vmoviles INC no tiene la obligaci&oacute;n de filtrar, editar o supervisar el Contenido
          generado por el usuario, la Compa&ntilde;&iacute;a se reserva el derecho de hacerlo, a su exclusivo criterio,
          y de eliminar o editar cualquier Contenido generado por el usuario sin previo aviso, en cualquier momento y a
          su entera discreci&oacute;n Usted ser&aacute; el &uacute;nico responsable, en su extensi&oacute;n, de realizar
          copias de seguridad y cambiar todo el Contenido generado por el usuario publicado en los Servicios.</p>
        <p>Cuando carga, publica, p&uacute;blica o pone a disposici&oacute;n cualquier Contenido generado por el usuario
          en la Plataforma o utiliza dicho Contenido generado por el usuario, otorga: (a) a la empresa un derecho
          irrevocable, perpetuo, no exclusivo, libre de regal&iacute;as, transferible, asignable, sublicencia y licencia
          mundial para usar, reproducir, distribuir, transmitir, hacer trabajos derivados, mostrar, copiar, poner a
          disposici&oacute;n del p&uacute;blico y realizar ese contenido generado por el usuario, junto con detalles,
          marcas comerciales, marcas de servicio y logotipos, en relaci&oacute;n con el Plataforma, ya sea a
          trav&eacute;s de Internet, cualquier dispositivo m&oacute;vil o de cualquier otro tipo, en cualquier formato
          de medios y a trav&eacute;s de cualquier canal medial conocido hoy y desarrollado en el futuro; (b) A
          cualquier Usuario de los Servicios, una licencia no exclusiva y libre de regal&iacute;as para ingresar
          Contenido generado por el usuario y hacer cualquier uso privado y no comercial del mismo.</p>
        <p>Vmoviles INC puede usar y publicar cualquier parte del Contenido generado por el usuario sin permiso y a su
          exclusivo criterio. Usted comprende y acepta que no tendr&aacute; derecho a impedir cualquier transferencia de
          Contenido generado por el usuario a terceros. Vmoviles INC no ser&aacute; responsable de ning&uacute;n uso de
          Contenido generado por el usuario por parte de terceros. Adem&aacute;s, renuncia a cualquier derecho moral que
          pueda tener con respecto al Contenido generado por el usuario y acuerda nunca exigir ni asignar ning&uacute;n
          derecho sobre el Contenido generado por el usuario.</p>
        <p>Vmoviles INC no ser&aacute; responsable ni tendr&aacute; ninguna consecuencia derivada de la carga,
          publicaci&oacute;n, presentaci&oacute;n y puesta a disposici&oacute;n de cualquier Contenido generado por el
          usuario por parte del Usuario o de un tercero, ni de ning&uacute;n da&ntilde;o o p&eacute;rdida. Vmoviles INC
          no ser&aacute; responsable de ning&uacute;n error, error, difamaci&oacute;n, maldici&oacute;n o
          pornograf&iacute;a a la que usted o un tercero puedan estar expuestos.</p>
        <ol start="21">
          <li><strong>Agente de mala conducta y derecho de autor</strong></li>
        </ol>
        <p>Cuidamos su seguridad y bienestar. Si cree que un usuario, incluidos los Proveedores externos,
          actu&oacute; de manera inapropiada, incluido, entre otros, el contenido ofensivo, violento o de comportamiento
          sexual inapropiado, informe inmediatamente a dicha persona a las autoridades correspondientes y a
          nosotros.</p>
        <p>La Compa&ntilde;&iacute;a respeta los derechos de propiedad intelectual de otros. Si cree que su trabajo ha
          sido copiado de una manera que constituye una infracci&oacute;n de derechos de autor, por favor proporcione la
          siguiente informaci&oacute;n por escrito al agente de derechos de autor de la empresa: (a) una firma
          f&iacute;sica o electr&oacute;nica de una persona autorizada para actuar en nombre del propietario del
          copyright; (b) una descripci&oacute;n de la obra protegida por derechos de autor que usted reclama ha sido
          violada; (c) una descripci&oacute;n del material que usted declara estar infringiendo o ser objeto de una
          actividad infractora y que debe ser eliminado o cuyo acceso debe ser inhabilitado, e informaci&oacute;n
          suficiente para permitir que la Compa&ntilde;&iacute;a ubique el material (incluyendo Direcci&oacute;n URL o
          captura de pantalla de dicha actividad infractora); (d) informaci&oacute;n para que la Compa&ntilde;&iacute;a
          pueda contactarlo, como direcci&oacute;n, n&uacute;mero de tel&eacute;fono y direcci&oacute;n de correo
          electr&oacute;nico; (e) una declaraci&oacute;n de que usted cree de buena fe que el uso del material de la
          manera denunciada no est&aacute; autorizado por el propietario de los derechos de autor, su agente o la ley; y
          (f) una declaraci&oacute;n de que la informaci&oacute;n en la notificaci&oacute;n es precisa y, bajo pena de
          perjurio, usted es el propietario de los derechos de autor o est&aacute; autorizado para actuar en nombre del
          propietario de un derecho de autor que presuntamente se ha infringido. El agente de derechos de autor de la
          empresa puede contactarse a trav&eacute;s de Contact Us en el sitio web.</p>
        <ol start="22">
          <li><strong>General</strong></li>
        </ol>
        <p>Estos t&eacute;rminos no se interpretan, ni se interpretar&aacute;n para crear ninguna relaci&oacute;n de
          asociaci&oacute;n, empresa conjunta, empleador-empleado, agencia o franquiciador-franquiciado entre las partes
          del presente. Cualquier reclamo relacionado con los Servicios o el uso del Servicio se regir&aacute; e
          interpretar&aacute; de conformidad con las leyes del Estado Mexicano, sin referencia a sus principios de
          conflicto de leyes. Cualquier disputa que surja de o est&eacute; relacionada con su uso de los Servicios
          ser&aacute; presentada, y por la presente usted acepta la jurisdicci&oacute;n y jurisdicci&oacute;n exclusivas
          en los tribunales competentes de Tijuana Baja California, M&eacute;xico. Si se determina que alguna
          disposici&oacute;n de estos T&eacute;rminos es ilegal, nula o inaplicable por alg&uacute;n motivo, dicha
          disposici&oacute;n se considerar&aacute; separable de estos T&eacute;rminos y no afectar&aacute; la validez y
          aplicabilidad de cualquier disposici&oacute;n restante. No puede ceder, sublicenciar ni transferir de ninguna
          manera ninguno o todos sus derechos u obligaciones bajo estos T&eacute;rminos sin el previo consentimiento
          expreso por escrito de Vmoviles INC. Ninguna renuncia de cualquiera de las partes a ning&uacute;n
          incumplimiento o incumplimiento se considerar&aacute; una renuncia a cualquier incumplimiento o incumplimiento
          anterior o posterior. Cualquier encabezado, t&iacute;tulo o t&iacute;tulo de la secci&oacute;n que se incluye
          en este documento se inserta solo como una cuesti&oacute;n de conveniencia, y de ninguna manera define o
          explica ninguna secci&oacute;n o disposici&oacute;n del mismo. Estos T&eacute;rminos son los t&eacute;rminos y
          condiciones completos entre usted y Vmoviles INC relacionados con el objeto del presente y reemplazan todos y
          cada uno de los acuerdos o acuerdos anteriores o contempor&aacute;neos escritos o verbales entre usted y
          Vmoviles INC. Las notificaciones a usted pueden hacerse por correo electr&oacute;nico o por correo postal.
          Vmoviles INC tambi&eacute;n puede proporcionar avisos de cambios a estos T&eacute;rminos u otros asuntos,
          mostrando dichos avisos o proporcionando enlaces a dichos avisos. Sin limitaci&oacute;n, acepta que una
          versi&oacute;n impresa de estos T&eacute;rminos y de cualquier notificaci&oacute;n dada en forma
          electr&oacute;nica ser&aacute; admisible en procedimientos judiciales o administrativos basados ​​o
          relacionados con estos T&eacute;rminos en la misma medida y sujetos a las mismas condiciones que otros
          documentos comerciales y registros originalmente generados y mantenidos en forma impresa. Las actualizaciones
          del Servicio se pueden descargar autom&aacute;ticamente de vez en cuando a su tel&eacute;fono inteligente y
          OBD Dongle (las "Actualizaciones"). Las actualizaciones est&aacute;n dise&ntilde;adas para mejorar, enriquecer
          y desarrollar los servicios por medio de: errores y accesorios de errores, opciones avanzadas, nuevas
          funcionalidades y ajustes para que coincidan con las nuevas versiones de hardware y software. Usted representa
          y acepta aceptar las Actualizaciones de la Compa&ntilde;&iacute;a, y le permite a la Compa&ntilde;&iacute;a
          empujar e instalar las Actualizaciones autom&aacute;tica y directamente en el tel&eacute;fono inteligente,
          como parte del uso de los Servicios. Por la presente, permite que la empresa utilice notificaciones push como
          medio de comunicaci&oacute;n con el usuario a trav&eacute;s del tel&eacute;fono inteligente.</p>
        <p>&nbsp;</p>
        <ol start="23">
          <li><strong>Para informaci&oacute;n, preguntas o notificaci&oacute;n de errores, cont&aacute;ctese
            con:</strong></li>
        </ol>
        <p>Si tiene alguna pregunta (o comentario) sobre los T&eacute;rminos, puede enviar un correo electr&oacute;nico
          a HS Drive a: contact@HS Drive.app, o usar la Aplicaci&oacute;n presionando el bot&oacute;n
          "Cont&aacute;ctenos", y HS Drive har&aacute; un esfuerzo para responder dentro de un marco de tiempo
          razonable. Al contactar a la Compa&ntilde;&iacute;a, usted garantiza y acepta que es libre de hacerlo, y que
          no le proporciona a HS Drive informaci&oacute;n que viole los derechos intelectuales de un tercero (la
          "Informaci&oacute;n"). Sin perjuicio de lo anterior, todos los derechos, incluida la propiedad intelectual,
          derivados de la comunicaci&oacute;n ser&aacute;n propiedad de la Compa&ntilde;&iacute;a y se
          considerar&aacute;n como material confidencial de Vmoviles INC. Se aclara que cualquier uso de la
          Informaci&oacute;n se har&aacute; a exclusivo criterio de la Compa&ntilde;&iacute;a, y la
          Compa&ntilde;&iacute;a no est&aacute; obligada a utilizar toda o parte de esta Informaci&oacute;n.</p>
        <p>No est&aacute; obligado a ser un usuario registrado para visitar el Sitio. Sin embargo, si desea contactarnos
          a trav&eacute;s de los Servicios, deber&aacute; completar y completar el formulario de Contacto. El formulario
          Cont&aacute;ctenos requiere su direcci&oacute;n de correo electr&oacute;nico, y el resto de la
          informaci&oacute;n es opcional. Adem&aacute;s, puede contactarnos a trav&eacute;s del bot&oacute;n
          Cont&aacute;ctenos en la Aplicaci&oacute;n.</p>
      </GridItem>
    </GridContainer>
  );
}

Terms.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(style)(Terms);
