import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import sidebarStyle from 'assets/jss/material-dashboard-react/components/sidebarStyle.jsx';
import classNames from 'classnames';
import HeaderLinks from 'components/Header/HeaderLinks.jsx';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { compose } from 'redux';

const Sidebar = ({ ...props }) => {
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return props.location !== undefined &&
      props.location.pathname.indexOf(routeName) > -1
      ? true
      : false;
  }
  const { classes, color, logo, image, logoText, routes, t } = props;
  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        if (prop.redirect) return null;
        var activePro = ' ';
        var listItemClasses;
        if (
          prop.path === '/login' ||
          prop.path === '/profile' ||
          prop.path === '/terms_and_conditions' ||
          prop.path === '/privacy_policy' ||
          prop.path.includes('forgot') ||
          prop.path.includes('validate')
        ) {
          return null;
        } else {
          listItemClasses = classNames({
            [' ' + classes[color]]: activeRoute(prop.path)
          });
        }
        const whiteFontClasses = classNames({
          [' ' + classes.whiteFont]: activeRoute(prop.path)
        });
        return (
          <NavLink
            to={prop.path}
            className={activePro + classes.item}
            activeClassName='active'
            key={key}
          >
            <ListItem button className={classes.itemLink + listItemClasses}>
              <ListItemIcon className={classes.itemIcon + whiteFontClasses}>
                {typeof prop.icon === 'string' ? (
                  <Icon>{prop.icon}</Icon>
                ) : (
                  <prop.icon />
                )}
              </ListItemIcon>
              <ListItemText
                primary={t(prop.sidebarName)}
                className={classes.itemText + whiteFontClasses}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
        );
      })}
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <a
        href='http://vmoviles.com/'
        target={'_blank'}
        className={classes.logoLink}
      >
        <div className={classes.logoImage}>
          <img src={logo} alt='logo' className={classes.img} />
        </div>
        {logoText}
      </a>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation='css'>
        <Drawer
          variant='temporary'
          anchor='right'
          open={props.open}
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            <HeaderLinks />
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ background: 'linear-gradient(180deg,#2E2F2C,#4A4A4A)' }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation='css'>
        <Drawer
          anchor='left'
          variant='permanent'
          open
          classes={{
            paper: classes.drawerPaper
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ background: 'linear-gradient(180deg,#2E2F2C,#4A4A4A)' }}
            />
          ) : null}
          <p
            style={{
              zIndex: 'inherit',
              alignSelf: 'center'
            }}
          >
            Version 0.4.7
          </p>
        </Drawer>
      </Hidden>
    </div>
  );
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDrawerToggle: PropTypes.func,
  location: PropTypes.object,
  open: PropTypes.bool,
  routes: PropTypes.array,
  t: PropTypes.func,
  logoText: PropTypes.string,
  image: PropTypes.string,
  logo: PropTypes.string,
  color: PropTypes.string
};

export default compose(
  withStyles(sidebarStyle),
  withTranslation('translations')
)(Sidebar);
