/**
 * Clebber
 *
 * Created by Edgar Lopez on 04/15/19.
 * Copyright © 2018 intqd. All rights reserved.
 */

import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import logo from "assets/img/logo_hsdrive.png";

const style = {
  grid: {
    padding: "0 15px !important",
    justifyContent: "space-between"
  },
  logo: {
    float: "right"
  }
};

function Policy({ ...props }) {
  return (
    <GridContainer
      style={{ justifyContent: "space-around", margin: "0 0 !important" }}
    >
      <GridItem>
        <h2><strong>Pol&iacute;tica de
          Privacidad. &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</strong>
          <img className={props.classes.logo} src={logo} alt="logo" width="228" height="134"/></h2>
        <p>&nbsp;</p>
        <p><strong>Vmoviles INC (HS Drive)</strong></p>
        <p><strong>&Uacute;ltima revisi&oacute;n: 28 de mayo 2019</strong></p>
        <p>&nbsp;</p>
        <p>La presente Pol&iacute;tica de Privacidad establece los t&eacute;rminos en que Vmoviles INC
          (&ldquo;nosotros&rdquo; o &ldquo;compa&ntilde;&iacute;a&rdquo;) usa y protege la informaci&oacute;n que es
          proporcionada por sus usuarios al momento de utilizar el sitio web de la compa&ntilde;&iacute;a, la plataforma
          de software y todas las aplicaciones de tel&eacute;fonos inteligentes asociadas (colectivamente, "la
          plataforma"). Esta compa&ntilde;&iacute;a est&aacute; comprometida con la seguridad de los datos de sus
          usuarios. Cuando le pedimos llenar los campos de informaci&oacute;n personal con la cual usted pueda ser
          identificado, lo hacemos asegurando que s&oacute;lo se emplear&aacute; de acuerdo con los t&eacute;rminos de
          este documento. Sin embargo, esta Pol&iacute;tica de Privacidad puede cambiar con el tiempo o ser actualizada
          por lo que le recomendamos y enfatizamos revisar continuamente esta p&aacute;gina para asegurarse que
          est&aacute; de acuerdo con dichos cambios.<span className="Apple-converted-space">&nbsp;</span></p>
        <p>Esta Pol&iacute;tica de privacidad solo se aplica a la Plataforma (HS Drive), aplicaci&oacute;n (HS Drive) o
          sitio web de la compa&ntilde;&iacute;a, y no a otros sitios web o aplicaciones a los que pueda acceder desde
          la Plataforma, cada uno de los cuales puede tener pr&aacute;cticas y pol&iacute;ticas de recolecci&oacute;n,
          almacenamiento y uso de datos que difieran sustancialmente de esta Privacidad Pol&iacute;tica.</p>
        <p>&nbsp;</p>
        <p><strong>Informaci&oacute;n que es recogida</strong></p>
        <p>Nuestro sitio web podr&aacute; recoger informaci&oacute;n personal, por ejemplo: Nombre, informaci&oacute;n
          de contacto como&nbsp;su direcci&oacute;n de correo electr&oacute;nico e informaci&oacute;n
          demogr&aacute;fica. As&iacute; mismo cuando sea necesario podr&aacute; ser requerida informaci&oacute;n
          espec&iacute;fica para procesar alg&uacute;n pedido o realizar una entrega o facturaci&oacute;n.</p>
        <p>Para que pueda acceder a ciertos servicios y productos que ofrecemos a trav&eacute;s de la Plataforma, le
          solicitamos que nos brinde cierta informaci&oacute;n que lo identifica personalmente ("Informaci&oacute;n
          personal"). La informaci&oacute;n personal puede incluir las siguientes categor&iacute;as de
          informaci&oacute;n: (1) datos de contacto (como su nombre, direcci&oacute;n postal y de correo
          electr&oacute;nico, u otra informaci&oacute;n opcional que puede proporcionar a trav&eacute;s de una
          aplicaci&oacute;n); (2) Datos financieros (como su cuenta o n&uacute;mero de tarjeta de cr&eacute;dito); y (3)
          Datos demogr&aacute;ficos (como su c&oacute;digo postal, edad e ingresos). Si se comunica con nosotros por
          correo electr&oacute;nico, publica mensajes en cualquiera de nuestros grupos de chat, boletines
          electr&oacute;nicos o foros, o completa formularios en l&iacute;nea, encuestas o entradas al concurso,
          cualquier informaci&oacute;n provista en dicha comunicaci&oacute;n se puede recopilar como informaci&oacute;n
          personal. Esto incluir&aacute; las direcciones de correo electr&oacute;nico de quienes se comunican con
          nosotros por correo electr&oacute;nico, informaci&oacute;n agregada sobre a qu&eacute; p&aacute;ginas acceden
          o visitan los consumidores, e informaci&oacute;n voluntaria (por ejemplo, informaci&oacute;n de encuestas y /
          o registros de sitios).</p>
        <p>&nbsp;</p>
        <p><strong>Usos de la informaci&oacute;n recopilada</strong></p>
        <p>Usamos datos de contacto para enviarle informaci&oacute;n sobre nuestra compa&ntilde;&iacute;a o nuestros
          productos o servicios, o material promocional de algunos de nuestros socios, o para contactarlo cuando sea
          necesario. Utilizamos sus datos financieros para verificar su elegibilidad para ciertos productos o servicios
          y para enviarle una factura por productos y servicios. Utilizamos sus datos demogr&aacute;ficos para
          personalizar y adaptar su experiencia en la plataforma o aplicaci&oacute;n, mostrando contenido que creemos
          que le puede interesar y de acuerdo con sus preferencias. Nosotros y las aplicaciones disponibles en la
          Plataforma podemos utilizar la Informaci&oacute;n personal para comunicarnos con usted con respecto a
          informaci&oacute;n que pueda interesarle o para ofrecerle cupones, servicios, consejos, informaci&oacute;n u
          otras promociones canjeables. Si desea darse de baja de tales comunicaciones, siga las instrucciones de
          cancelaci&oacute;n de suscripci&oacute;n provistas a trav&eacute;s de la aplicaci&oacute;n dada o la que se
          proporciona en dicha comunicaci&oacute;n.</p>
        <p>Nuestro sitio web emplea la informaci&oacute;n con el fin de proporcionar el mejor servicio posible,
          particularmente para mantener un registro de usuarios, de pedidos en caso de que aplique, y mejorar nuestros
          productos y servicios. &nbsp;Es posible que sean enviados correos electr&oacute;nicos peri&oacute;dicamente a
          trav&eacute;s de nuestro sitio con ofertas especiales, nuevos productos y otra informaci&oacute;n publicitaria
          que consideremos relevante para usted o que pueda brindarle alg&uacute;n beneficio, estos correos
          electr&oacute;nicos ser&aacute;n enviados a la direcci&oacute;n que usted proporcione y podr&aacute;n ser
          cancelados en cualquier momento.</p>
        <p>Vmoviles INC est&aacute; altamente comprometido para cumplir con el compromiso de mantener su
          informaci&oacute;n segura. Usamos los sistemas m&aacute;s avanzados y los actualizamos constantemente para
          asegurarnos que no exista ning&uacute;n acceso no autorizado.</p>
        <p>&nbsp;</p>
        <p><strong>Telem&aacute;tica</strong></p>
        <p>Los datos de nuestro servicio de autom&oacute;viles conectados HS Drive se utilizan para proporcionarle ese
          servicio de monitoreo de veh&iacute;culos, para habilitar las funciones de la aplicaci&oacute;n de movimiento
          asociada a HS Drive y para habilitar la conectividad Wifi en su autom&oacute;vil. Adem&aacute;s, sus datos
          pueden ser compartidos con nuestro proveedor de aplicaciones para habilitar los servicios de terceros que usan
          sus datos personales, aunque en tales casos a ning&uacute;n tercero se le otorgar&aacute; acceso a los datos
          que lo identifican sin antes obtener su consentimiento. Tambi&eacute;n podemos utilizar dichos datos para
          cualquiera de los otros fines enumerados en esta pol&iacute;tica, como el an&aacute;lisis interno, o para
          personalizar las ofertas que le proporcionamos.</p>
        <p>&nbsp;</p>
        <p><strong>Publicidad</strong></p>
        <p>Puede ver publicidades cuando visita nuestros sitios web, sitios web m&oacute;viles, aplicaciones
          m&oacute;viles o en su dispositivo. Podemos ayudar a los anunciantes a llegar mejor a nuestros clientes
          brind&aacute;ndoles cierta informaci&oacute;n, incluido el tipo de dispositivo, la informaci&oacute;n
          geogr&aacute;fica, las preferencias de idioma o la informaci&oacute;n demogr&aacute;fica obtenida de otras
          compa&ntilde;&iacute;as para que los anunciantes puedan determinar qu&eacute; anuncios pueden ser m&aacute;s
          relevantes para usted. Sin embargo, no compartimos informaci&oacute;n personal con fines publicitarios fuera
          de nuestra familia corporativa sin su consentimiento.</p>
        <p>&nbsp;</p>
        <p><strong>Cookies</strong></p>
        <p>Una cookie se refiere a un archivo que es enviado con la finalidad de solicitar permiso para almacenarse en
          su computadora, al aceptar dicho archivo se crea y la cookie sirve entonces para tener informaci&oacute;n
          respecto al tr&aacute;fico de un portal web, y tambi&eacute;n facilita las futuras visitas a un portal web
          recurrente. Otra funci&oacute;n que tienen las cookies es que con ellas los portales web pueden reconocerte
          individualmente y por tanto brindarte el mejor servicio personalizado de su portal web.</p>
        <p>Nuestro sitio web emplea las cookies para poder identificar las p&aacute;ginas que son visitadas y su
          frecuencia. Esta informaci&oacute;n es empleada &uacute;nicamente para an&aacute;lisis estad&iacute;stico y
          despu&eacute;s la informaci&oacute;n se elimina de forma permanente. Usted puede eliminar las cookies en
          cualquier momento desde su computadora. Sin embargo, las cookies ayudan a proporcionar un mejor servicio de
          los sitios web, est&aacute;s no dan acceso a informaci&oacute;n de su computadora ni de usted, a menos de que
          usted as&iacute; lo quiera y la proporcione directamente. Usted puede aceptar o negar el uso de cookies, sin
          embargo, la mayor&iacute;a de los navegadores aceptan cookies autom&aacute;ticamente pues sirve para tener un
          mejor servicio de internet. Tambi&eacute;n usted puede cambiar la configuraci&oacute;n de su computadora para
          declinar las cookies. Si se declinan es posible que no pueda utilizar algunos de nuestros servicios.</p>
        <p>&nbsp;</p>
        <p><strong>Enlaces a Terceros</strong></p>
        <p>Este sitio web pudiera contener enlaces a otros sitios que pudieran ser de su inter&eacute;s. Una vez que
          usted de clic en estos enlaces y abandone nuestra p&aacute;gina, ya no tenemos control sobre al sitio al que
          es redirigido y por lo tanto no somos responsables de los t&eacute;rminos o privacidad ni de la
          protecci&oacute;n de sus datos en esos otros sitios terceros. Dichos sitios est&aacute;n sujetos a sus propias
          pol&iacute;ticas de privacidad por lo cual es recomendable que los consulte para confirmar que usted
          est&aacute; de acuerdo con estas.</p>
        <p>&nbsp;</p>
        <p><strong>Control de su informaci&oacute;n personal</strong></p>
        <p>En cualquier momento usted puede restringir la recopilaci&oacute;n o el uso de la informaci&oacute;n personal
          que es proporcionada a nuestro sitio web.&nbsp; Cada vez que se le solicite rellenar un formulario, como el de
          alta de usuario, puede marcar o desmarcar la opci&oacute;n de recibir informaci&oacute;n por correo
          electr&oacute;nico. &nbsp;En caso de que haya marcado la opci&oacute;n de recibir nuestro bolet&iacute;n o
          publicidad usted puede cancelarla en cualquier momento.</p>
        <p>Esta compa&ntilde;&iacute;a no vender&aacute;, ceder&aacute; ni distribuir&aacute; la informaci&oacute;n
          personal que es recopilada sin su consentimiento, salvo que sea requerido por un juez con un orden
          judicial.</p>
        <p>&nbsp;</p>
        <p><strong>Cambios</strong></p>
        <p>Nos reservamos el derecho, en cualquier momento y sin previo aviso, de agregar, cambiar, actualizar o
          modificar esta Pol&iacute;tica de privacidad, simplemente publicando dicho cambio, actualizaci&oacute;n o
          modificaci&oacute;n en el sitio web de la compa&ntilde;&iacute;a y sin ning&uacute;n otro aviso para usted.
          Cualquier cambio, actualizaci&oacute;n o modificaci&oacute;n de este tipo se har&aacute; efectivo
          inmediatamente despu&eacute;s de su publicaci&oacute;n en el sitio web de la compa&ntilde;&iacute;a.</p>
      </GridItem>
    </GridContainer>
  );
}

Policy.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(style)(Policy);
