import {
  CHANGE_FORM,
  FORGOT_PASSWORD,
  GET_USER,
  LOGGED_IN,
  LOGIN_REQUEST,
  LOGOUT,
  REGISTER_REQUEST,
  REQUEST_ERROR,
  RESET_PASSWORD,
  SET_CURRENT_TRIP,
  SET_VALIDATE_EMAIL,
  UPDATE_USER
} from './constants';

export function loggedIn() {
  return { type: LOGGED_IN };
}

export function forgotPassword(params) {
  return { type: FORGOT_PASSWORD, params };
}

export function resetPassword(params) {
  return { type: RESET_PASSWORD, params };
}
export function changeForm(newFormState) {
  return { type: CHANGE_FORM, newFormState };
}

export function loginRequest(data) {
  return { type: LOGIN_REQUEST, data };
}

export function logout() {
  return { type: LOGOUT };
}

export function registerRequest(data) {
  return { type: REGISTER_REQUEST, data };
}

export function updateUser(data) {
  return { type: UPDATE_USER, data };
}

export function requestError(error) {
  return { type: REQUEST_ERROR, error };
}

export function setCurrentTrip(id) {
  return { type: SET_CURRENT_TRIP, id };
}

export function getUser() {
  return { type: GET_USER };
}

export function validateEmail(data) {
  return { type: SET_VALIDATE_EMAIL, data };
}
