/* eslint-disable no-console */
import API from '../api/ClebberRestApi';
import storage from '../utils/storage';
var moment = require('moment');

const TIME_FORMAT = 'hh:mm a';

const clebber = {
  async getCarList() {
    return await Promise.all(
      await API.getCarList().then(async response => {
        return await response.data;
      })
    );
  },

  async getTripHistory(cid) {
    return await Promise.all(
      await API.getTrips(cid, 0)
        .then(async response => {
          return await response.data.trips.map(async trip => {
            const on_time = trip.position_on.time;
            const off_time = trip.position_off.time;
            trip.start_address_time = clebber.dateFormats(on_time, TIME_FORMAT);
            trip.end_address_time = clebber.dateFormats(off_time, TIME_FORMAT);
            trip.duration = clebber.dateDifferent(on_time, off_time);
            return trip;
          });
        })
        .catch(err => {
          return ['error: ' + err];
        })
    );
  },

  async getTripPosition(tid) {
    return await API.getTripPosition(tid).then(async response => {
      return await response.data.map(marker => {
        const { latitude, longitude } = marker;
        let latlng = {};
        latlng.lat = latitude;
        latlng.lng = longitude;
        return latlng;
      });
    });
  },

  async addGeofence(params) {
    return await API.addGeofence(params);
  },

  async updateGeofence(params) {
    return await API.updateGeofence(params);
  },

  async changePassword(params) {
    return await API.changePassword(params);
  },

  async deleteGeofence(gid) {
    return await API.deleteGeofence(gid);
  },

  async getNotifications() {
    return await API.getUserNotifications().then(response => response.data);
  },
  async getCarNotifications(cId) {
    return await API.getCarNotifications(cId).then(response => response.data);
  },
  async getBattery(cid) {
    return await API.getBatteryStatic(cid).then(response => {
      const { data } = response;
      let stats = {
        series: [[]],
        labels: []
      };
      if (data.length > 0) {
        for (let i = 0; i < data.length; i += 1) {
          stats.series[0].push(data[i].min);
          stats.labels.push(moment(data[i].date).format('MM/D'));
        }
      }
      return stats;
    });
  },

  async deleteCar(cid) {
    return await API.deleteCar(cid);
  },

  async forgotPassword(params) {
    return await API.sendEmailResetPass(params);
  },

  async resetPassword(params) {
    return await API.resetPassword(params);
  },

  async getGeofences(cid) {
    return await API.getGeofences(cid).then(response => response.data);
  },
  /**
   * Login / register
   */
  async login(params) {
    const { username, password } = params;
    return await API.login(username, password).then(response => {
      // Save token to local storage
      const { data } = response;
      if (!response.ok || !data || !data.access || !data.access.access) {
        return Promise.resolve(false);
      }
      storage.saveUser(data);
      storage.saveToken(data);
      API.setToken(data.access.access);
      return data.user;
    });
  },

  logout() {
    API.setToken('');
    return storage.deleteAll();
  },
  /**
   * Checks if a user is logged in
   */
  loggedIn() {
    API.setToken(storage.getToken());
    return storage.isEmpty();
  },
  /**
   * Registers a user and then logs them in
   * @param  {string} username The username of the user
   * @param  {string} password The password of the user
   */
  register(username, password) {
    // Post a fake request
    return (
      API.createUser({ username, password })
        // Log user in after registering
        .then(() => clebber.login(username, password))
    );
  },
  updateUser(parameters) {
    return API.updateUser(parameters).then(() => {
      console.log('user updated!');
    });
  },
  validateEmail(parameters) {
    return API.validateEmail(parameters);
  },
  updateCar(parameters) {
    return API.updateCar(parameters);
  },
  onChange() {
    console.log('change');
  },
  user() {
    return storage.getUser();
  },
  /**
   * Utility
   */
  dateFormats(date, format) {
    let time = moment(new Date(date));
    return time.format(format).toString();
  },
  dateDifferent(start, end) {
    let time = moment(new Date(end)).diff(new Date(start), 'minutes');
    return time.toString() + ' minutes';
  }
};

export default clebber;
