import storage from './../utils/storage';

const unitConverter = {
  isMilles() {
    return (
      storage.getUnitSystem() === 'Miles' ||
      storage.getUnitSystem() === 'Millas'
    );
  },
  efficiency(item) {
    return this.isMilles()
      ? (
          item.driving_distance /
          (item.driving_fuel_consum + item.idle_fuel_consum) /
          0.425
        ).toFixed(2)
      : (
          item.driving_distance /
          (item.driving_fuel_consum + item.idle_fuel_consum)
        ).toFixed(2);
  },
  kmToMilesNew(unit) {
    let result = unit;
    if (unit) {
      if (this.isMilles()) return (result * 0.62137).toFixed(2);
      return (result * 1).toFixed(3);
    }
    return 0;
  },
  kmToMiles(unit) {
    if (unit)
      return (this.isMilles() ? (unit / 1000) * 0.621371 : unit / 1000).toFixed(
        3
      );
    return 0;
  },
  radius(unit) {
    if (unit)
      return (this.isMilles() ? (unit / 1000) * 0.621371 : unit / 1000).toFixed(
        1
      );
    return 0;
  },
  hoursUnit() {
    return this.isMilles() ? 'MPH' : 'KPH';
  },
  litersUnit() {
    return this.isMilles() ? 'MPG' : 'KPL';
  },
  radiusUnit() {
    return this.isMilles() ? 'MI' : 'KM';
  },
  kmUnit() {
    return this.radiusUnit(this.isMilles());
  },
  perHourUnit() {
    return this.hoursUnit(this.isMilles());
  },
  perLiterUnit() {
    return this.litersUnit(this.isMilles());
  }
};

export default unitConverter;
