import { all } from "redux-saga/effects";
import { authSaga } from "./auth";
import { dashboardSaga } from "./dashboard";
import { mycarsSaga } from "./mycars";
import { overviewSaga } from "./overview";
import { geofenceSaga } from "./geofence";

export default function* root() {
  yield all([
    ...authSaga,
    ...dashboardSaga,
    ...overviewSaga,
    ...geofenceSaga,
    ...mycarsSaga
  ]);
}

// // Little helper function to abstract going to different pages
// function* forwardTo(location) {
//   yield put(push(location));
// }
