import Icon from '@material-ui/core/Icon';
import withStyles from '@material-ui/core/styles/withStyles';
import icon_imei from 'assets/img/icon_imei_blue.png';
import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import ChartistGraph from 'react-chartist';
import { withTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { compose } from 'redux';
import { dailySalesChart } from 'variables/charts';
import unitConverter from 'variables/unitConverter';
import moment from 'moment';
import 'moment/locale/es';
const calendarStrings = {
  lastDay: ' [Yesterday] [at] LT',
  sameDay: ' [at] LT',
  lastWeek: ' [last], dddd [at] LT',
  sameElse: ' L'
};

function CardInfo({ ...props }) {
  const { classes, car, trip, battery, option, t } = props;
  const carProblems = data => {
    if (data.checkEngine !== 0) {
      return t('CheckEngine');
    }
    if (data.dtcCodes.length > 0) {
      return (
        t('DtcProblem') +
        R.pathOr('unknow', ['dtcCodes', 0, 'code'])(data) +
        ' - ' +
        (localStorage.getItem('language') === 'Español'
          ? R.pathOr('unknow', ['dtcCodes', 0, 'es'])(data)
          : R.pathOr('unknow', ['dtcCodes', 0, 'en'])(data))
      );
    }
    return t('Good');
  };

  if (localStorage.getItem('language') === 'Español') {
    moment.locale('es');
  } else {
    moment.locale('en');
  }
  switch (option) {
    case 0:
      return (
        <Card>
          <CardHeader color='success' style={{ display: 'flex' }}>
            <CardIcon>
              <Icon
                style={{ fontSize: '5.0rem', color: '#FFFFFF', margin: '20px' }}
              >
                directions_car
              </Icon>
            </CardIcon>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column'
              }}
            >
              <p className={classes.cardTitleWhite}>
                {car.nickname ? car.nickname : 'New Vehicle'}
              </p>
              <p className={classes.cardStatusWhite}>
                {car.device && car.device.state.toLowerCase() === 'disconnected'
                  ? 'Offline'
                  : 'Online'}
              </p>
            </div>
          </CardHeader>
          <CardBody>
            <p className={classes.cardRowText}>
              {t('DateAdded')}
              <span className={classes.textAlignRight}>
                {car.time && <Moment format=' D MMM, YYYY'>{car.time}</Moment>}
              </span>
            </p>
            <hr />
            <p className={classes.cardRowText}>
              {t('License')}
              <span className={classes.textAlignRight}>{car.plate}</span>
            </p>
            <hr />
            <p className={classes.cardRowText}>
              VIN
              <span className={classes.textAlignRight}>{car.vin}</span>
            </p>
            <hr />
            <p className={classes.cardRowText}>
              {t('Odometer')}({unitConverter.kmUnit()})
              <span className={classes.textAlignRight}>
                {unitConverter.kmToMiles(car.odometer)}
              </span>
            </p>
            <hr />
            <p className={classes.cardRowText}>
              <img
                src={icon_imei}
                alt='imei'
                style={{ verticalAlign: 'middle' }}
              />{' '}
              IMEI
              <span className={classes.textAlignRight}>
                {car.device && car.device.imei}
              </span>
            </p>
          </CardBody>
        </Card>
      );
    case 1:
      return (
        <Card>
          <CardHeader color='info' style={{ display: 'flex' }}>
            <CardIcon>
              <Icon
                style={{ fontSize: '5.0rem', color: '#FFFFFF', margin: '20px' }}
              >
                location_on
              </Icon>
            </CardIcon>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column'
              }}
            >
              <p
                className={classes.cardTitleWhite}
                style={{ fontWeight: 'bold' }}
              >
                {t('LastTripDone')}
              </p>
              <p>{trip.end_address}</p>
            </div>
          </CardHeader>
          <CardBody
            style={{
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <p className={classes.cardRowText} style={{ textAlign: 'center' }}>
              {car.nickname ? t('TripOf') + car.nickname + t('HasEnded') : ''}
              <b>
                {trip.end_address}
                {trip.position_off && (
                  <Moment calendar={calendarStrings}>
                    {trip.position_off.time}
                  </Moment>
                )}
              </b>
            </p>
          </CardBody>
        </Card>
      );
    case 2:
      return (
        <Card>
          <CardHeader color='info' style={{ display: 'flex' }}>
            <CardIcon>
              <Icon
                style={{ fontSize: '5.0rem', color: '#FFFFFF', margin: '20px' }}
              >
                build
              </Icon>
            </CardIcon>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column'
              }}
            >
              <p
                className={classes.cardTitleWhite}
                style={{ fontWeight: 'bold' }}
              >
                {car.dtcCodes.length > 0 || car.checkEngine
                  ? t('ProblemDetected')
                  : t('EverythingGood')}
              </p>
              <p className={classes.cardTitleWhite} />
            </div>
          </CardHeader>
          <CardBody>
            <p className={classes.cardRowText}>
              {car.dtcCodes.length > 0
                ? carProblems(car)
                : car.checkEngine
                ? t('CheckEngine')
                : ''}
            </p>
          </CardBody>
        </Card>
      );
    case 3:
      const data =
        R.pathOr([], ['series', 0])(battery).length > 0 ? battery : {};
      return (
        <Card chart>
          <CardHeader color='yellow'>
            <ChartistGraph
              className='ct-chart'
              data={data}
              type='Line'
              options={dailySalesChart.options}
              listener={dailySalesChart.animation}
            />
          </CardHeader>
          <CardBody>
            <p className={classes.cardRowText}>{t('BatteryInfo')}</p>
          </CardBody>
        </Card>
      );
    default:
      return null;
  }
}

CardInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  option: PropTypes.number.isRequired,
  battery: PropTypes.object,
  car: PropTypes.object,
  trip: PropTypes.object,
  t: PropTypes.func
};

export default compose(
  withStyles(dashboardStyle),
  withTranslation('translations')
)(CardInfo);
