/* eslint-disable no-console */
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import withStyles from '@material-ui/core/styles/withStyles';
import Switch from '@material-ui/core/Switch';
import EditIcon from '@material-ui/icons/Edit';
import profile from 'assets/img/icn_profilepicture.png';
import bg from 'assets/img/profile_background.png';
import profileStyles from 'assets/jss/material-dashboard-react/views/profileStyles.jsx';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import { compose } from 'recompose';
import { getUser, updateUser } from 'actions/auth';
import { setLenguage, setUnits } from 'actions/dashboard';
import storage from '../../utils/storage';
import FormControlLabel from '@material-ui/core/FormControlLabel';

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      nameDisabled: true,
      name: '',
      email: '',
      emailDisabled: true,
      phone: '(   )    -    ',
      phoneDisabled: true,
      user: {},
      lang: false,
      measurement: false
    };
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
    this.props.updateUser({
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone
    });
  };

  handleChangeCheck = prop => () => {
    if (prop !== storage.getLanguage()) {
      this.props.setLenguage(prop);
    }
  };

  handleChangeCheckMes = prop => () => {
    if (prop !== storage.getUnitSystem()) {
      this.props.setUnits(prop);
      this.setState({ measurement: !this.state.measurement });
    }
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  componentDidMount() {
    this.props.getUser();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const lang = storage.getLanguage() !== 'English';
    const measurement = storage
      .getUnitSystem()
      .toLowerCase()
      .includes('mil');
    this.setState({
      name: nextProps.user.name,
      email: nextProps.user.email,
      phone: nextProps.user.phone,
      lang,
      measurement
    });
  }

  handleEnable = prop => () => {
    this.setState({ [prop + 'Disabled']: !this.state[prop + 'Disabled'] });
  };

  render() {
    // eslint-disable-next-line

    const { classes, t } = this.props;
    const {
      name,
      nameDisabled,
      email,
      emailDisabled,
      phone,
      phoneDisabled
    } = this.state;
    return (
      <div
        style={{
          height: '85vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <div
          classes={classes.boxShadow}
          style={{
            backgroundImage: 'url(' + bg + ')',
            width: '90%',
            height: '90%',
            borderRadius: 20,
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img alt='userImage' src={profile} />

          <div
            style={{
              marginTop: '5%',
              width: '80%',
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center'
            }}
          >
            <div>
              <p style={{ marginLeft: 10, fontWeight: 'bold' }}>{t('Name')}</p>
              <div className={classes.switchContainer}>
                <InputBase
                  value={name}
                  classes={{
                    root: classes.bootstrapRoot,
                    input: classes.inputStyle
                  }}
                  disabled={nameDisabled}
                  onChange={this.handleChange('name')}
                  style={{ width: '17vw' }}
                />
                <IconButton
                  aria-label='Toggle password visibility'
                  onClick={this.handleEnable('name')}
                >
                  <EditIcon color={'error'} />
                </IconButton>
              </div>
              <p style={{ marginLeft: 10, fontWeight: 'bold', marginTop: 10 }}>
                {t('Email')}
              </p>
              <div className={classes.switchContainer}>
                <InputBase
                  value={email}
                  classes={{
                    root: classes.bootstrapRoot,
                    input: classes.inputStyle
                  }}
                  disabled={emailDisabled}
                  onChange={this.handleChange('email')}
                  style={{ width: '17vw' }}
                />
                <IconButton onClick={this.handleEnable('email')}>
                  <EditIcon color={'error'} />
                </IconButton>
              </div>
              <p style={{ marginLeft: 10, fontWeight: 'bold', marginTop: 10 }}>
                {t('CellPhone')}
              </p>
              <div className={classes.switchContainer}>
                <InputBase
                  value={phone}
                  classes={{
                    root: classes.bootstrapRoot,
                    input: classes.inputStyle
                  }}
                  disabled={phoneDisabled}
                  onChange={this.handleChange('phone')}
                  inputComponent={TextMaskCustom}
                  style={{ width: '17vw' }}
                />
                <IconButton
                  aria-label='Toggle password visibility'
                  onClick={this.handleEnable('phone')}
                >
                  <EditIcon color={'error'} />
                </IconButton>
              </div>
            </div>
            <div>
              <p style={{ marginLeft: 10, fontWeight: 'bold' }}>
                {t('Language')}
              </p>
              <div className={classes.switchContainer}>
                <p>{t('Spanish')}</p>
                <FormControlLabel
                  control={
                    <Switch
                      checked={storage.getLanguage() !== 'English'}
                      onChange={this.handleChangeCheck('Español')}
                      value='checkedB'
                    />
                  }
                />
              </div>
              <div className={classes.switchContainer}>
                <p>{t('English')}</p>
                <FormControlLabel
                  control={
                    <Switch
                      checked={storage.getLanguage() === 'English'}
                      onChange={this.handleChangeCheck('English')}
                      value='checkedB'
                    />
                  }
                />
              </div>
              <p style={{ marginLeft: 10, fontWeight: 'bold', marginTop: 10 }}>
                {t('MeasurementSystem')}
              </p>
              <div className={classes.switchContainer}>
                <p>{t('ISOMetric')}</p>
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        !storage
                          .getUnitSystem()
                          .toLowerCase()
                          .includes('mil')
                      }
                      onChange={this.handleChangeCheckMes('Kilometers')}
                      value='checkedB'
                    />
                  }
                />
              </div>
              <div className={classes.switchContainer}>
                <p>{t('ImperialMetric')}</p>
                <FormControlLabel
                  control={
                    <Switch
                      checked={storage
                        .getUnitSystem()
                        .toLowerCase()
                        .includes('mil')}
                      onChange={this.handleChangeCheckMes('Miles')}
                      value='checkedB'
                    />
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user
  };
}

const mapDispatchToProps = {
  getUser: getUser,
  updateUser: updateUser,
  setLenguage: setLenguage,
  setUnits: setUnits
};

Profile.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object,
  updateUser: PropTypes.func,
  setLenguage: PropTypes.func,
  getUser: PropTypes.func,
  theme: PropTypes.object.isRequired,
  setUnits: PropTypes.func,
  inputRef: PropTypes.func
};

export default compose(
  withStyles(profileStyles, { withTheme: true }),
  withRouter,
  withTranslation('translations'),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Profile);
