/**
 * Clebber
 *
 * Created by Edgar Lopez on 10/22/18.
 * Copyright © 2018 intqd. All rights reserved.
 */

import {
  GEOFENCE_DATA,
  GEOFENCE_SET_LIST,
  GEOFENCE_DELETE,
  GEOFENCE_ADD,
  GEOFENCE_UPDATE
} from "./constants";

export function getData(cid) {
  return { type: GEOFENCE_DATA, cid };
}

export function setData(data) {
  return { type: GEOFENCE_SET_LIST, data };
}

export function deleteGeofence(data) {
  return { type: GEOFENCE_DELETE, data };
}

export function addGeofence(data) {
  return { type: GEOFENCE_ADD, data };
}

export function updateGeofence(data) {
  return { type: GEOFENCE_UPDATE, data };
}
