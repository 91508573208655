/**
 * Clebber
 *
 * Created by Edgar Lopez on 08/30/18.
 * Copyright © 2018 intqd. All rights reserved.
 */

import {
  OVERVIEW_CURRENT_CAR,
  OVERVIEW_TRIP_HISTORY,
  OVERVIEW_TRIP_DETAIL_POSITION,
  LOGOUT
} from "../actions/constants";

// The initial application state
let initialState = {
  error: "",
  cars: [],
  lastTrip: {},
  tripHistory: [],
  tripDetail: []
};

// Takes care of changing the application state
export default function overview(state = initialState, action) {
  switch (action.type) {
    case LOGOUT: {
      return initialState;
    }
    case OVERVIEW_CURRENT_CAR:
      return { ...state, cars: action.data || [] };
    case OVERVIEW_TRIP_HISTORY: {
      const tripHistory = action.data || [];
      const lastTrip = action.data[0] || {};
      return {
        ...state,
        tripHistory,
        lastTrip
      };
    }
    case OVERVIEW_TRIP_DETAIL_POSITION:
      return { ...state, tripDetail: action.data || [] };
    default:
      return state;
  }
}
