import { connectRouter } from 'connected-react-router/immutable';
import { combineReducers } from "redux";
import { sessionReducer } from "redux-react-session";
import auth from "./auth";
import dashboard from "./dashboard";
import geofence from "./geofence";
import mycars from "./mycars";
import overview from "./overview";

export default (history) => combineReducers({
  router: connectRouter(history),
  auth,
  dashboard,
  overview,
  geofence,
  mycars,
  session: sessionReducer
});
