import BarChart from '@material-ui/icons/BarChart';
import Dashboard from '@material-ui/icons/Dashboard';
import DirectionsCar from '@material-ui/icons/DirectionsCar';
import Geofence from '@material-ui/icons/MyLocation';
import ForgotPage from 'views/Access/ForgotPassword.jsx';
import ForgotSuccess from 'views/Access/ForgotSuccess.jsx';
import LoginPage from 'views/Access/Login.jsx';
import ResetPassword from 'views/Access/ResetPassword.jsx';
import ValidateEmail from 'views/Access/ValidateEmail';
import CarsPage from 'views/Cars/Cars.jsx';
import DashboardPage from 'views/Dashboard/Dashboard.jsx';
import GeofencePage from 'views/Geofence/Geofence.jsx';
import PoliciesPage from 'views/Legal/Policy.jsx';
import TermsPage from 'views/Legal/Terms.jsx';
import OverviewPage from 'views/Overview/Overview.jsx';
import Profile from 'views/Profile';

const dashboardRoutes = [
  {
    path: '/profile',
    navbarName: 'Profile',
    component: Profile
  },
  {
    path: '/dashboard',
    sidebarName: 'Dashboard',
    navbarName: 'Dashboard',
    icon: Dashboard,
    component: DashboardPage
  },
  {
    path: '/overview',
    sidebarName: 'Overview',
    navbarName: 'Overview',
    icon: BarChart,
    component: OverviewPage
  },
  {
    path: '/geofence',
    sidebarName: 'Geofence',
    navbarName: 'Geofence',
    icon: Geofence,
    component: GeofencePage
  },
  {
    path: '/cars',
    sidebarName: 'MyCars',
    navbarName: 'MyCars',
    icon: DirectionsCar,
    component: CarsPage
  },
  {
    path: '/terms_and_conditions',
    sidebarName: 'Terms and Conditions',
    navbarName: 'Terms',
    component: TermsPage
  },
  {
    path: '/privacy_policy',
    sidebarName: 'Privacy Policies',
    navbarName: 'Policies',
    component: PoliciesPage
  },
  {
    path: '/login',
    sidebarName: 'Login',
    navbarName: 'Login',
    component: LoginPage
  },
  {
    path: '/forgot',
    sidebarName: 'Forgot',
    navbarName: 'Forgot',
    component: ForgotPage
  },
  {
    path: '/forgot_password/:token',
    sidebarName: 'Forgot Password',
    navbarName: 'Forgot Password',
    component: ResetPassword
  },
  {
    path: '/validate/:token',
    sidebarName: 'Validate email',
    navbarName: 'Validate email',
    component: ValidateEmail
  },
  {
    path: '/forgot_success',
    sidebarName: 'Forgot Success',
    navbarName: 'Forgot Success',
    component: ForgotSuccess
  },
  {
    redirect: true,
    path: '/terms_and_conditions',
    to: '/terms_and_conditions'
  },
  { redirect: true, path: '/privacy_policy', to: '/privacy_policy' },
  { redirect: true, path: '/', to: '/login' }
];

export default dashboardRoutes;
