/**
 * Clebber
 *
 * Created by Edgar Lopez on 09/01/18.
 * Copyright © 2018 intqd. All rights reserved.
 */

import { call, put, takeLatest } from 'redux-saga/effects';
import clebber from '../api';
import { setTrips, setTripDetailPosition } from '../actions/overview';
import {
  OVERVIEW_DATA,
  OVERVIEW_GET_TRIP_DETAIL,
  OVERVIEW_CURRENT_CAR,
  DASHBOARD_CARLIST_SUCCESS
} from '../actions/constants';

export function* getOverviewData(params) {
  try {
    const car = yield call(clebber.getCarList);
    if (car) {
      yield put({ type: DASHBOARD_CARLIST_SUCCESS, carList: car });
      yield put({ type: OVERVIEW_CURRENT_CAR, car });
      if (params.cid) {
        const trips = yield call(clebber.getTripHistory, params.cid);
        yield put(setTrips(trips));
        const lastTrip = trips[0] || {};
        if (lastTrip.id) {
          const positions = yield call(clebber.getTripPosition, lastTrip.id);
          yield put(setTripDetailPosition(positions));
        } else {
          yield put(setTripDetailPosition(undefined));
        }
      } else {
        const trips = yield call(clebber.getTripHistory, car[0].id);
        yield put(setTrips(trips));
        const lastTrip = trips[0] || {};
        if (lastTrip.id) {
          const positions = yield call(clebber.getTripPosition, lastTrip.id);
          yield put(setTripDetailPosition(positions));
        } else {
          yield put(setTripDetailPosition(undefined));
        }
      }
    }
  } catch (error) {
    console.log('Error:', error);
  }
}

export function* getOverviewTripDetail(params) {
  try {
    const positions = yield call(clebber.getTripPosition, params.tid);
    yield put(setTripDetailPosition(positions));
  } catch (error) {
    console.log('Error:', error);
  }
}

export const overviewSaga = [
  takeLatest(OVERVIEW_DATA, getOverviewData),
  takeLatest(OVERVIEW_GET_TRIP_DETAIL, getOverviewTripDetail)
];
