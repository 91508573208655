import {
  CHANGE_FORM,
  CURRENT_TRIP,
  LOGIN_REQUEST,
  REQUEST_ERROR,
  SET_AUTH,
  SET_BATTERY_DATA,
  UPDATE_USER,
  VALIDATE_EMAIL_FAIL,
  VALIDATE_EMAIL_SUCCESS
} from '../actions/constants';
import clebber from '../api';

// The initial application state
let initialState = {
  formState: {
    username: '',
    password: ''
  },
  error: false,
  loggedIn: !clebber.loggedIn,
  user: undefined,
  currentTrip: undefined,
  battery: undefined,
  modal: {
    title: '',
    content: ''
  }
};

// Takes care of changing the application state
function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_USER:
      return { ...state, data: action.data };
    case CHANGE_FORM:
      return { ...state, formState: action.newFormState };
    case SET_AUTH:
      return { ...state, user: action.user };
    case LOGIN_REQUEST:
      return { ...state, error: false };
    case REQUEST_ERROR:
      return { ...state, modal: action.modal, error: true };
    case CURRENT_TRIP:
      return { ...state, currentTrip: action.data.id };
    case SET_BATTERY_DATA:
      return { ...state, battery: action.battery };
    case VALIDATE_EMAIL_SUCCESS:
      return { ...state, success: true };
    case VALIDATE_EMAIL_FAIL:
      return { ...state, success: false };
    default:
      return state;
  }
}

export default reducer;
