/**
 * Clebber
 *
 * Created by Edgar Lopez on 08/30/18.
 * Copyright © 2018 intqd. All rights reserved.
 */

import {
  MYCARS_LIST,
  MYCARS_LIST_SUCCESS,
  MYCARS_DELETE_SUCCESS,
  MYCARS_DELETE,
  MYCARS_UPDATE,
  MYCARS_UPDATE_SUCCESS,
  LOGOUT
} from '../actions/constants';

// The initial application state
let initialState = {
  error: '',
  carList: [],
  successDelete: false
};

// Takes care of changing the application state
export default function mycars(state = initialState, action) {
  switch (action.type) {
    case LOGOUT: {
      return initialState;
    }
    case MYCARS_LIST:
      return { ...state };
    case MYCARS_LIST_SUCCESS:
      return { ...state, carList: action.carList };
    case MYCARS_DELETE:
      return { ...state, successDelete: false, error: '' };
    case MYCARS_DELETE_SUCCESS:
      return { ...state, successDelete: true };
    case MYCARS_UPDATE:
      return { ...state, successDelete: false, error: '' };
    case MYCARS_UPDATE_SUCCESS:
      return { ...state, successDelete: true };
    default:
      return state;
  }
}
