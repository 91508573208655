import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import logo from 'assets/img/logo_hsdrive.png';
import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { resetPassword } from 'actions/auth';
import Language from 'components/Language';

const MySwal = withReactContent(Swal);
class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: '',
      repeatPassword: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { newPassword, repeatPassword } = this.state;
    if (
      newPassword &&
      repeatPassword &&
      R.pathOr(false, ['match', 'params', 'token'])(this.props)
    ) {
      this.setState({ submitted: true });
      const params = {
        password: newPassword,
        token: R.pathOr(false, ['match', 'params', 'token'])(this.props)
      };
      this.props.resetPassword(params);
    }
  }

  componentDidUpdate() {
    const { error } = this.props;
    if (error && this.state.submitted) {
      this.setState({ submitted: false });
      MySwal.fire({
        type: 'error',
        title: 'Opps...',
        text:
          'There was an error in the server please recheck your password and token.'
      });
    }
  }

  render() {
    const { classes, t } = this.props;
    const { submitted, newPassword, repeatPassword } = this.state;
    return (
      <div>
        <Language></Language>
        <GridContainer
          style={{ justifyContent: 'space-around', margin: '0 0 !important' }}
        >
          <GridItem xs={12} sm={6} md={4}>
            <Card profile style={{ backgroundColor: 'transparent' }}>
              <CardBody style={{ backgroundColor: 'transparent' }}>
                <img src={logo} alt='logo' height='120' />
                <p>{t('EnterNewPassword')}</p>
                <form
                  className={classes.container}
                  noValidate
                  autoComplete='off'
                  style={{ display: 'grid', marginBottom: '20px' }}
                >
                  {submitted && (
                    <CircularProgress className={classes.progress} size={50} />
                  )}
                  <TextField
                    required
                    id='newPassword'
                    name='newPassword'
                    label={t('NewPassword')}
                    className={classes.textField}
                    onChange={this.handleChange}
                    margin='normal'
                    type='password'
                    style={{ width: '90%' }}
                  />
                  <TextField
                    required
                    id='repeatPassword'
                    name='repeatPassword'
                    label={t('RepeatPassword')}
                    className={classes.textField}
                    onChange={this.handleChange}
                    margin='normal'
                    type='password'
                    style={{ width: '90%' }}
                  />
                </form>
                <Button
                  type='submit'
                  color='info'
                  style={{
                    width: '100%'
                  }}
                  onClick={this.handleSubmit}
                  disabled={
                    newPassword !== repeatPassword || repeatPassword.length < 5
                  }
                >
                  {t('ChangePassword')}
                </Button>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    error: state.auth.error,
    modal: state.auth.modal
  };
}

const mapDispatchToProps = {
  resetPassword: resetPassword
};

ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  resetPassword: PropTypes.func,
  error: PropTypes.bool,
  modal: PropTypes.object,
  t: PropTypes.func
};

export default compose(
  withStyles(dashboardStyle),
  withTranslation('translations'),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ResetPassword);
